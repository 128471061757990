import styled from "styled-components";

export const ResetRight = styled.section`
  padding: 32px;
  padding-top: 100px;
`;

export const FormWrapper = styled.div`
  padding-top: 32px;
`;
