import * as utilities from "./http.utilities";
import * as urlUtilities from "../Url";

export function get<ResponseData extends unknown>(
  url: string,
  params?: any,
  options?: { headers?: Record<string, string>; authentification?: boolean }
) {
  const urlToFetch = `${url}${urlUtilities.url.toUrlSearchString(params)}`;
  return utilities.request<ResponseData>("GET", urlToFetch, params, options);
}

export function post<ResponseData extends unknown>(
  url: string,
  data: any,
  options?: { headers?: Record<string, string>; authentification?: boolean }
) {
  return utilities.request<ResponseData>("POST", url, data, options);
}

export function put<ResponseData extends unknown>(
  url: string,
  data: any,
  options?: { headers?: Record<string, string>; authentification?: boolean }
) {
  return utilities.request<ResponseData>("PUT", url, data, options);
}
