import { http } from "../../Utilities";
import { API_ENDPOINTS } from "./VerifyEmail.constants";
import { ExchangeTokenResponse } from "./VerifyEmail.types";

export function postExchangeToken(token: string) {
  return http.post<ExchangeTokenResponse>(
    API_ENDPOINTS.EXCHANGE_AUTH_TOKEN,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
}
