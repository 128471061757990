import { Menu as AntdMenu } from "antd";
import * as types from "./Menu.types";

function Menu({ items, onClick }: types.MenuProps) {
  return (
    <AntdMenu items={items} inlineCollapsed mode="inline" onClick={onClick} />
  );
}

export default Menu;
