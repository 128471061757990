import { Radio } from "antd";
import styled from "styled-components";

export const RadioGroupWrapper = styled(Radio.Group)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const RadioButton = styled(Radio.Button)`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  &&&:before {
    display: none;
  }
`;
