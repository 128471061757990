export const BRAND_PROPOSAL_STEPS = [
  {
    title: "Basic Details",
  },
  {
    title: "Influencer Eligibility",
  },
  {
    title: "Guilde Requirements",
  },
];

export const API_ENDPOINTS = {
  CAMPAIGN: "/client/brand/campaign/:campaignId",
  UPDATE_CAMPAIGN: "/campaign",
  PROPOSAL_META: "/meta/campaign-proposal",
} as const;
