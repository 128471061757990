import * as types from "./RequestForgotPassword.types";
import * as CONSTANTS from "./RequestForgotPassword.constants";
import { http } from "../../Utilities";

export function postResetPassword(
  resetPasswordData: types.ForgotPasswordFormData
) {
  return http.post<types.ForgotPasswordResponse>(
    CONSTANTS.API_ENDPOINTS.FORGOT_PASSWORD,
    resetPasswordData
  );
}
