import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Custom hook to update multiple query parameters.
 * @returns A function to update multiple query parameters.
 */
function useUpdateQueryParams(): (params: { [key: string]: string }) => void {
  const [, setSearchParams] = useSearchParams();
  /**
   * Updates multiple query parameters in the URL.
   * @param params - An object containing key-value pairs of the query parameters to update.
   */

  const updateQueryParams = useCallback(
    (params: { [key: string]: string }) => {
      setSearchParams((old) => {
        const newSearchParams = new URLSearchParams(old);
        Object.keys(params).forEach((key) => {
          newSearchParams.set(key, params[key]);
        });
        return newSearchParams;
      });
    },
    [setSearchParams]
  );

  return updateQueryParams;
}

export { useUpdateQueryParams };
