import { dateTime } from "../../Utilities";
import * as types from "./BrandCampaigns.types";

export function transformMyCampaigns(
  response: types.RawMyCampaignResponse
): types.CampaignsListResponse {
  if (!response?.success) {
    return { campaigns: [] };
  }
  const campaigns = response.result.map(({ campaign, actions }) => {
    return {
      id: campaign.id,
      title: campaign.title,
      description: campaign.description,
      budget: campaign.budget,
      launchDate:
        dateTime.dateTime.formatDayMonthYear(campaign.launch_date) || "-",
      goals: campaign.goals.map((goal) => goal.title).join(", "),
      status: campaign.creation_status,
      actions,
    };
  });
  return { campaigns };
}
