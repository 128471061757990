import styled from "styled-components";

export const ResetWrapper = styled.main`
  display: grid;
  grid-template-columns: 3fr 2fr;
  height: 100vh;
  padding: 16px;
`;

export const ResetLeft = styled.section`
  background: linear-gradient(-30deg, #5d38e0, #ff5b77);
  border-radius: 16px;
`;

export const ResetRight = styled.section`
  padding: 32px;
  padding-top: 100px;
`;

export const FormWrapper = styled.div`
  padding-top: 32px;
`;
