import styled, { css } from "styled-components";
import * as types from "./Text.types";
import { STYLES } from "../../Constants";
import * as CONSTANTS from "./Text.constants";
import { style } from "../../Utilities";

export const AppText = styled.p<types.TextStyledProps>`
  ${({ $color, $fontSize, $fontWeight, $lineHeight, $textAlign }) => css`
    color: ${STYLES.COLORS[$color]};
    font-size: ${style.covertPixelToRem(CONSTANTS.FONT_SIZE[$fontSize])};
    font-weight: ${CONSTANTS.FONT_WEIGHT[$fontWeight]};
    line-height: ${$lineHeight};
    text-align: ${CONSTANTS.TEXT_ALIGN[$textAlign]};
  `}
`;
