import { DatePicker as AntdDatePicker } from "antd";
import * as types from "./DatePicker.types";

function DatePicker({ placeholder, onChange }: types.DatePickerProps) {
  return (
    <AntdDatePicker
      placeholder={placeholder}
      width="300px"
      onChange={(date) => onChange?.(date.toDate().toISOString())}
    />
  );
}

export default DatePicker;
