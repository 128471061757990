import {
  Button,
  Form,
  FormItem,
  Text,
  Input,
  Avatar,
  ButtonLikeLink,
} from "../../Components";
import { url } from "../../Utilities/Url";
import { UserInfoWrapper, UserTextInfoWrapper } from "./Login.styles";
import * as types from "./Login.types";
import { validatePassword } from "./Login.utilities";

export function UserExistsForm({
  isLoading,
  onSubmit,
}: types.UserExistsFormProps) {
  return (
    <Form<types.UserStatusFormData> onSubmit={onSubmit}>
      <FormItem
        label={<Text>Enter Email</Text>}
        name="email"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input placeholder="eg. xyz@gmail.com" autoFocus type="email" />
      </FormItem>
      <FormItem>
        <Button
          text="Continue with Email"
          fullWidth
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
        />
      </FormItem>
    </Form>
  );
}

export function SignInForm({
  isLoading,
  onSubmit,
  userData,
}: types.SignInFormProps) {
  return (
    <div>
      <UserInfoWrapper>
        <Avatar
          src="https://www.w3schools.com/howto/img_avatar.png"
          size={64}
          alt="User Avatar"
        />
        <UserTextInfoWrapper>
          <Text fontWeight="SEMI_BOLD">{userData.email}</Text>
          <Text color="SECONDARY_TEXT">{userData.email}</Text>
        </UserTextInfoWrapper>
      </UserInfoWrapper>
      <Form<types.SignInFormData> onSubmit={onSubmit}>
        <FormItem hidden name="email" initialValue={userData.email}>
          <input type="hidden" value={userData.email} />
        </FormItem>
        <FormItem label={<Text>Password</Text>} name="password">
          <Input placeholder="eg. xyz@gmail.com" type="password" autoFocus />
        </FormItem>
        <FormItem>
          <Button
            text="Proceed to Sign In"
            fullWidth
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
          />
        </FormItem>
        <ButtonLikeLink
          to={`/forgot-password${url.toUrlSearchString({
            email: userData.email,
          })}`}
          text="Forgot Password"
          type="LINK"
        />
      </Form>
    </div>
  );
}

export function SignUpForm({
  isLoading,
  onSubmit,
  userData,
}: types.SignUpFormProps) {
  return (
    <Form<types.SignUpFormData> onSubmit={onSubmit}>
      <FormItem
        label={<Text>Enter Email</Text>}
        name="email"
        initialValue={userData.email}
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input placeholder="eg. xyz@gmail.com" />
      </FormItem>
      <FormItem
        label={<Text>Password</Text>}
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const passwordError = validatePassword(value);
              if (!passwordError) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(passwordError));
            },
          }),
        ]}
      >
        <Input placeholder="eg. xyz@gmail.com" type="password" autoFocus />
      </FormItem>
      <FormItem
        label={<Text>Confirm Password</Text>}
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The new password that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input placeholder="eg. xyz@gmail.com" type="password" />
      </FormItem>
      <FormItem>
        <Button
          text="Proceed to Sign Up"
          fullWidth
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
        />
      </FormItem>
    </Form>
  );
}
