import { Form } from "antd";
import * as types from "./FormItem.types";

export default function FormItem({
  children,
  label,
  name,
  hidden,
  initialValue,
  rules,
  valuePropName,
}: types.FormItemProps) {
  return (
    <Form.Item
      label={label}
      name={name}
      hidden={hidden}
      initialValue={initialValue}
      rules={rules}
      valuePropName={valuePropName}
    >
      {children}
    </Form.Item>
  );
}
