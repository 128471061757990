import { PieChart as RePieChart, Pie, Sector, Cell } from "recharts";
import * as types from "./DonutChart.types";
import { STYLES } from "../../Constants";

const renderActiveShape = (
  props: any,
  { chartCenterText }: { chartCenterText: string }
) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fontWeight={600}
        fontSize={16}
        fill={STYLES.COLORS.SECONDARY_TEXT}
      >
        {chartCenterText}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

function PieChart({ chartCenterText, data }: types.DonutChartProps) {
  return (
    <RePieChart width={120} height={120}>
      <Pie
        data={data}
        innerRadius={40}
        outerRadius={60}
        dataKey="value"
        activeIndex={0}
        activeShape={(props: any) =>
          renderActiveShape(props, { chartCenterText })
        }
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
    </RePieChart>
  );
}

export default PieChart;
