import * as types from "./CreateCampaign.types";

export function transformCampaignMeta(
  response: types.CampaignMetaRawResponse
): types.CampaignMeta {
  return {
    goals: response.result.goals
      .filter((goal) => !goal.is_deleted)
      .map((goal) => ({
        label: goal.title,
        value: goal.id,
        description: goal.description,
      })),
    types: response.result.campaign_types.map((type) => ({
      label: type.title,
      value: type.title,
      description: type.description,
    })),
    locations: response.result.locations.map((location) => ({
      label: location.city,
      value: location.id,
    })),
    ages: response.result.age_ranges.map((age) => ({
      value: age,
      label: age,
    })),
    genders: response.result.genders.map((gender) => ({
      value: gender,
      label: gender,
    })),
    languages: response.result.languages.map((language) => ({
      label: language.name,
      value: language.id,
    })),
    creatorNiches: response.result.ideal_creator_niches.map((niche) => ({
      value: niche,
      label: niche,
    })),
    followersCounts: response.result.minimum_follower_counts,
    engagementRates: response.result.minimum_engagement_rates,
    rawResponse: response,
  };
}

export function transformCreateCampaignFormData(
  formData: types.CreateCampaignFormData,
  meta: types.CampaignMetaRawResponse
) {
  const campaignDetails = transformCampaignFormData(formData, meta);
  const audienceDetails = transformAudienceFormData(formData, meta);
  return { ...campaignDetails, ...audienceDetails };
}

function transformCampaignFormData(
  campaignFormData: types.CreateCampaignFormData,
  meta: types.CampaignMetaRawResponse
) {
  const { description, goal, title, typeOfCampaign } = campaignFormData;
  const selectedGoal = meta.result.goals.find(
    (objective) => objective.id === goal
  );
  const campaignDetails = {
    title,
    description,
    ...(selectedGoal && { goals: [selectedGoal] }),
    ...(typeOfCampaign && { campaign_type: typeOfCampaign }),
  } as const;
  return campaignDetails;
}

function transformAudienceFormData(
  audienceFormData: types.CreateCampaignFormData,
  meta: types.CampaignMetaRawResponse
) {
  const targetAudience = {
    ...(audienceFormData.ageRange?.length && {
      age_ranges: audienceFormData.ageRange,
    }),
    ...(audienceFormData.gender && { gender: audienceFormData.gender }),

    ...(audienceFormData.location?.length && {
      locations: audienceFormData.location.map((location) =>
        meta.result.locations.find((loc) => loc.id === location)
      ),
    }),
  } as const;
  return {
    ...(Object.keys(targetAudience).length && {
      target_audience: targetAudience,
    }),
  } as const;
}
