import * as types from "./Url.types";

export function toUrlSearchString(params: Record<string, string>): string {
  const searchParams = new URLSearchParams(params);

  // Return the search string prefixed with '?'
  return `?${searchParams.toString()}`;
}

/**
 * This utils is to help replace path varibles with param value
 *  @example
 * replacePathParams('/pre/:id', {id: 10});
 * returns '/pre/10'
 * @example
 * replacePathParams('/pre/:id?', {id: 10});
 * returns '/pre/10'
 * @example
 * replacePathParams('/pre/:id?');
 * returns '/pre'
 * @export
 * @param {string} path
 * @param {object} [params={}]
 * @returns
 */
export function replacePathParams<Url extends string>(
  urlOrPath: Url,
  params: types.ParseUrlParam<Url> = {} as types.ParseUrlParam<Url>
) {
  if (!urlOrPath) {
    return "";
  }
  let path = urlOrPath;
  let prePath = "";
  try {
    const url = new URL(urlOrPath);
    path = url.href.replace(url.origin, "") as Url;
    prePath = url.origin;
    // eslint-disable-next-line no-empty
  } catch (error) {}
  const hasStartingSlash = path.startsWith("/");
  const hasTrailingSlash = path.endsWith("/");
  const pathFragments = path
    .split("/")
    .map((fragment) => {
      if (!fragment || !fragment.startsWith(":")) {
        return fragment;
      }
      if (fragment.includes("?") && !fragment.endsWith("?")) {
        throw new Error("InvalidPathFragmentError");
      }
      const copyFragment = fragment.replace(":", "").replace("?", "");
      // params is inferred as unknown here because this function
      // doesn't know what the params object will look like. That's
      // why we need to cast it to Record<string, unknown> to make
      // TypeScript happy.
      return (params as Record<string, unknown>)[copyFragment];
    })
    .filter(Boolean);
  return `${prePath}${hasStartingSlash ? "/" : ""}${pathFragments.join("/")}${
    hasTrailingSlash ? "/" : ""
  }`;
}
