export const COLORS = {
  PRIMARY_TEXT: "#362B48",
  SECONDARY_TEXT: "#5C6674",
  PRIMARY: "#7B6CF0",
  WHITE: "#FFFFFF",
  SECONDARY_BACKGROUND_COLOR: "#F6F7F8",
  PRIMARY_LIGHT_BACKGROUND_COLOR: "#FAFAFF",
  BORDER_COLOR: "#E0E3E5",
  ERROR_COLOR: "#E91B16",
} as const;

export const BASE_THEME = {
  token: {
    colorPrimary: COLORS.PRIMARY,
  },
};
