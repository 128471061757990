import { http } from "../../Utilities";
import * as CONSTANTS from "./Login.constants";
import * as types from "./Login.types";

export function getEmailStatus(email: string) {
  return http.get<types.UserStatusResponse>(
    CONSTANTS.API_ENDPOINTS.USER_EXISTS,
    { email }
  );
}

export function postSignIn(signInData: types.SignInFormData) {
  return http.post<types.UserSignInResponse>(
    CONSTANTS.API_ENDPOINTS.SIGN_IN,
    signInData
  );
}

export function postSignUp(signUpData: types.SignUpFormData) {
  return http.post<types.UserSignUpResponse>(
    CONSTANTS.API_ENDPOINTS.SIGN_UP,
    signUpData
  );
}

export function postGoogleSignIn(token: string) {
  return http.post<types.GoogleSignInResponse>(
    CONSTANTS.API_ENDPOINTS.GOOGLE_SIGN_IN,
    {
      token,
    }
  );
}
