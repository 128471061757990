import { http } from "../../Utilities";
import * as CONSTANTS from "./BrandCampaigns.constants";
import * as types from "./BrandCampaigns.types";

export function getCampaigns() {
  return http.get<types.RawMyCampaignResponse>(
    CONSTANTS.API_ENDPOINTS.MY_CAMPAIGNS
  );
}

export function updateCampaignCreateStatus({
  campaignId,
  status,
}: {
  campaignId: number;
  status: string;
}) {
  return http.put(CONSTANTS.API_ENDPOINTS.CAMPAIGN, {
    id: campaignId,
    updates: {
      creation_status: status,
    },
  });
}
