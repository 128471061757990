import * as types from "./BrandProposal.types";

export function transformCampaignProposal(
  response: types.CampaignApiRawResponse
): types.CampaignResponse {
  return {
    id: response.result.campaign.id,
    title: response.result.campaign.title,
    description: response.result.campaign.description,
    goals: response.result.campaign.goals.map((goal) => goal.title).join(", "),
    type: response.result.campaign.campaign_types.join(", "),
    gender: response.result.campaign.target_audience.gender,
    location: response.result.campaign.target_audience.locations
      .map((location) => location.city)
      .join(", "),
    ageGroup: response.result.campaign.target_audience.age_ranges.join(", "),
    language: response.result.campaign.target_audience.languages
      .map((language) => language.name)
      .join(", "),
    eligibility: response.result.campaign.eligibility,
  };
}

export function validateHashtags(hashtags: string) {
  const hashtagsArray = hashtags
    .trim()
    .split(" ")
    .every((hashtag) => hashtag.startsWith("#"));
  if (hashtagsArray) {
    Promise.resolve();
    return;
  }
  return Promise.reject(
    new Error("Hashtags should start with # and be separated by space")
  );
}
