// wrapper for fetch api

import { AUTH } from "../../Constants";
import { ValueOf } from "../Types";
import * as CONSTANTS from "./http.constants";
import * as types from "./http.types";

export async function request<ResponseData extends unknown>(
  method: ValueOf<typeof CONSTANTS.HTTP_METHODS>,
  url: string,
  data: any,
  { authentification = true, headers }: types.HTTP_OPTIONS = {}
) {
  const authToken = authentification
    ? localStorage.getItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY)
    : null;
  const response = await fetch(`/api${url}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      ...(authToken && {
        Authorization: `Bearer ${authToken}`,
      }),
      ...headers,
    },
    ...(method !== CONSTANTS.HTTP_METHODS.GET && {
      body: JSON.stringify(data),
    }),
  });
  const responseData = await response.json();
  return responseData as ResponseData;
}
