import { useQuery } from "@tanstack/react-query";
import { Skeleton, Text } from "../../Components";
import {
  BrandInsightsHeader,
  BrandInsightsWrapper,
  InfluencerDetailSection,
  InsightSectionWrapper,
} from "./BrandInsights.styles";
import { useParams, useSearchParams } from "react-router-dom";
import * as services from "./BrandInsights.services";
import * as utilities from "./BrandInsights.utilities";
import {
  CampaignStatusCard,
  InfluencerDetail,
  InsightCard,
  LivePost,
  TopSubmissions,
} from "./BrandInsights.HelperComponents";

function BrandInsights() {
  const { campaignId } = useParams<{ campaignId: string }>();

  const [searchParams] = useSearchParams();
  const submissionId = searchParams.get("submission_id") || "";

  const { data, isPending } = useQuery({
    queryKey: ["campaignInsights", campaignId, submissionId],
    queryFn: () =>
      services.getCampaignInsights(
        submissionId
          ? { submissionId, campaignId: null }
          : { campaignId: campaignId as string, submissionId: null }
      ),
    select: utilities.transformCampaignInsight,
  });

  if (isPending) {
    return <Skeleton />;
  }

  if (!data) {
    return null;
  }

  return (
    <BrandInsightsWrapper>
      <BrandInsightsHeader>
        <Text fontSize="LARGE" fontWeight="SEMI_BOLD">
          Campaign insights
        </Text>
        <Text color="SECONDARY_TEXT" fontSize="SMALL">
          Last Updated at: {data.campaign.lastUpdated}
        </Text>
      </BrandInsightsHeader>
      <InsightSectionWrapper>
        {data.partner && data.additionalInfo && data.submission ? (
          <InfluencerDetailSection>
            <InfluencerDetail
              partner={data.partner}
              additionalInfo={data.additionalInfo}
            />
            <LivePost submission={data.submission} />
          </InfluencerDetailSection>
        ) : null}
        <CampaignStatusCard campaign={data.campaign} />
        {data.insights.map((insight, index) => (
          <InsightCard insight={insight} key={index} />
        ))}
        {data.topPerformingSubmissions ? (
          <TopSubmissions
            topPerformingSubmissions={data.topPerformingSubmissions}
          />
        ) : null}
        <Text color="SECONDARY_TEXT" fontSize="XX_SMALL">
          *Data Disclaimer: The insights displayed on this dashboard are derived
          from a combination of actual data and estimated values based on
          available information. While we strive for accuracy, the data may not
          perfectly reflect true campaign performance. It is recommended to use
          these insights as a general guide and supplement them with additional
          analysis for critical decision-making.
        </Text>
      </InsightSectionWrapper>
    </BrandInsightsWrapper>
  );
}

export default BrandInsights;
