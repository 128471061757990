import { FormItem, Input, Select } from "../../Components";
import { TitleText } from "./CreateCampaign.styles";
import * as CONSTANTS from "./CreateCampaign.constants";
import * as types from "./CreateCampaign.types";

export function CampaignDetailsForm({
  goals,
  types,
}: types.CampaignDetailsFormProps) {
  return (
    <section>
      <TitleText fontWeight="SEMI_BOLD">Campaign details</TitleText>
      <FormItem label="Title" name="title">
        <Input placeholder="Enter Title " />
      </FormItem>
      <FormItem label="Description" name="description">
        <Input
          type="textarea"
          placeholder="Enter Description"
          count={{ show: true, max: CONSTANTS.MAX_DESCRIPTION_LIMIT }}
        />
      </FormItem>
      <FormItem label="Goal" name="goal">
        <Select options={goals} />
      </FormItem>
      <FormItem label="Type" name="typeOfCampaign">
        <Select options={types} />
      </FormItem>
    </section>
  );
}

export function TargetAudienceForm({
  ages,
  genders,
  locations,
}: types.TargetAudienceFormProps) {
  return (
    <section>
      <TitleText fontWeight="SEMI_BOLD">
        Target audience details (Optional)
      </TitleText>
      <FormItem label="Gender" name="gender">
        <Select options={genders} />
      </FormItem>
      <FormItem label="Location" name="location">
        <Select mode="multiple" options={locations} />
      </FormItem>
      <FormItem label="Age Group" name="ageRange">
        <Select mode="multiple" options={ages} />
      </FormItem>
    </section>
  );
}
