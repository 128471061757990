import * as types from "./Button.types";
import { StyledButton } from "./Button.styles";

export default function AppButton({
  text,
  type = "default",
  fullWidth = false,
  size = "middle",
  htmlType,
  loading = false,
  onClick,
  danger,
  noPadding,
}: types.ButtonProps) {
  return (
    <StyledButton
      type={type}
      block={fullWidth}
      size={size}
      htmlType={htmlType}
      loading={loading}
      onClick={onClick}
      danger={danger}
      $noPadding={noPadding}
    >
      {text}
    </StyledButton>
  );
}
