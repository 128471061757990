import { Table as AntdTable } from "antd";
import * as types from "./Table.types";

function Table<TableRowData extends object>({
  columns,
  data,
  rowKey,
  loading,
}: types.TableProps<TableRowData>) {
  return (
    <AntdTable
      columns={columns}
      dataSource={data}
      rowKey={rowKey}
      loading={loading}
      pagination={false}
    />
  );
}

export default Table;
