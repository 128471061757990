import { http } from "../../Utilities";
import { ValueOf } from "../../Utilities/Types";
import * as CONSTANTS from "./BrandSubmissions.constants";
import * as types from "./BrandSubmissions.types";

export function getReceivedSubmissions(
  campaignId: string,
  statuses: ValueOf<typeof CONSTANTS.SUBMISSION_STATUS>[]
) {
  return http.get<types.SubmissionsRawResponse>(
    CONSTANTS.API_ENDPOINTS.RECEIVED_SUBMISSIONS,
    {
      campaign_id: campaignId,
      submission_statuses: statuses,
    }
  );
}

export function getSubmissionDetails({
  applicationId,
  version,
}: {
  applicationId: number;
  version?: string | undefined;
}) {
  return http.get<types.SubmissionDetailRawResponse>(
    CONSTANTS.API_ENDPOINTS.SUBMISSION,
    {
      application_id: applicationId,
      ...(version && { version }),
    }
  );
}

export function postCreateFeedback({
  submissionId,
  feedbacks,
}: {
  submissionId: number;
  feedbacks: { text: string; videoTimeStamp: number }[];
}) {
  return http.post(CONSTANTS.API_ENDPOINTS.FEEDBACK, {
    submission_id: submissionId,
    author_id: 2,
    comments: feedbacks.map((feedback) => ({
      parent_comment: {
        text: feedback.text,
        video_timestamp: feedback.videoTimeStamp,
      },
    })),
  });
}

export function postCreateReply({
  submission_id,
  feedback_id,
  text,
  parent_comment_id,
}: {
  submission_id: number;
  feedback_id: number;
  text: string;
  parent_comment_id: number;
}) {
  return http.post(CONSTANTS.API_ENDPOINTS.COMMENT, {
    submission_id,
    feedback_id,
    text,
    parent_comment_id,
  });
}

export function updateSubmissionStatus({
  submissionId,
  status,
  rejectReason,
}: {
  submissionId: number;
  status: string;
  rejectReason?: {
    title: string;
    description: string;
  };
}) {
  return http.put(CONSTANTS.API_ENDPOINTS.SUBMISSIONS, {
    ids: [submissionId],
    updates: {
      status: status,
      ...(rejectReason && { rejection_reasons: [rejectReason] }),
    },
  });
}

export function updateSubmissionAsWrongPost({
  submissionId,
  reasons,
  comment,
}: {
  submissionId: number;
  reasons: string[];
  comment?: string;
}) {
  return http.put(CONSTANTS.API_ENDPOINTS.SUBMISSIONS, {
    ids: [submissionId],
    updates: {
      status: CONSTANTS.SUBMISSION_STATUS.POST_VERIFICATION_FAILED,
      wrong_post: {
        reasons,
        additional_comment: comment,
      },
    },
  });
}

export function getApplicationMeta() {
  return http.get<types.ApplicationMetaRawResponse>(
    CONSTANTS.API_ENDPOINTS.APPLICATION_META
  );
}
