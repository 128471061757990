import { Button, ButtonLikeLink, Text } from "../../Components";
import { useUpdateCampaignStatus } from "./BrandCampaigns.hooks";
import * as types from "./BrandCampaigns.types";

export function PrimaryAction({
  action,
  campaignId,
}: types.PrimaryActionProps) {
  const urlOrPath = action.onclick?.path || action.onclick?.url;
  const statusUpdate = action.onclick?.status_update;
  const { isPending, updateCampaignCreateStatus } = useUpdateCampaignStatus();
  if (urlOrPath) {
    return (
      <ButtonLikeLink
        text={action.label}
        to={urlOrPath}
        target={action.onclick.new_tab ? "_blank" : "_self"}
        type="LINK"
        noPadding
        align="right"
      />
    );
  }
  if (statusUpdate) {
    return (
      <Button
        noPadding
        text={
          <Text color="PRIMARY" fontSize="SMALL">
            {action.label}
          </Text>
        }
        type="text"
        onClick={() => {
          updateCampaignCreateStatus({
            campaignId: campaignId,
            status: statusUpdate,
          });
        }}
        loading={isPending}
      />
    );
  }

  return null;
}
