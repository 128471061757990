import { useSearchParams } from "react-router-dom";
import { Button, Form, FormItem, Input, Text } from "../../Components";
import { FormWrapper, ResetRight } from "./RequestForgotPassword.styles";
import * as types from "./RequestForgotPassword.types";
import { useMutation } from "@tanstack/react-query";
import * as services from "./RequestForgotPassword.services";

export default function RequestForgotPassword() {
  const [searchParams] = useSearchParams();

  const resetPasswordMutation = useMutation({
    mutationFn: services.postResetPassword,
  });

  function handleForgotPassword(formData: types.ForgotPasswordFormData) {
    resetPasswordMutation.mutate(formData);
  }

  return (
    <ResetRight>
      <Text fontWeight="SEMI_BOLD" fontSize="XXX_LARGE" as="h1" lineHeight="1">
        Forgot Password
      </Text>
      <Text color="SECONDARY_TEXT">Please enter your email address below</Text>
      {resetPasswordMutation.isSuccess && resetPasswordMutation.data.success ? (
        <FormWrapper>
          <Text color="SECONDARY_TEXT" fontSize="LARGE">
            Email sent successfully
          </Text>
          <Text color="SECONDARY_TEXT">
            Please check your email to reset your password
          </Text>
        </FormWrapper>
      ) : (
        <FormWrapper>
          <Form<types.ForgotPasswordFormData> onSubmit={handleForgotPassword}>
            <FormItem
              label={<Text>Enter Email</Text>}
              name="email"
              initialValue={searchParams.get("email") || ""}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input placeholder="eg. xyz@gmail.com" />
            </FormItem>
            <FormItem>
              <Button
                text="Send Email"
                fullWidth
                type="primary"
                size="large"
                htmlType="submit"
                loading={resetPasswordMutation.isPending}
              />
            </FormItem>
          </Form>
        </FormWrapper>
      )}
    </ResetRight>
  );
}
