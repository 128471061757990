import * as types from "./BrandCampaignForm.types";

export function transformCampaignMeta(
  response: types.CampaignMetaRawResponse
): types.CampaignMeta {
  return {
    objectives: response.result.campaign_objectives
      .filter((objective) => !objective.is_deleted)
      .map((objective) => ({
        label: objective.title,
        value: objective.id,
        description: objective.description,
      })),
    types: response.result.campaign_types.map((type) => ({
      label: type.title,
      value: type.title,
      description: type.description,
    })),
    locations: response.result.locations.map((location) => ({
      label: location.city,
      value: location.id,
    })),
    ages: response.result.age_ranges.map((age) => ({
      value: age,
      label: age,
    })),
    genders: response.result.genders.map((gender) => ({
      value: gender,
      label: gender,
    })),
    languages: response.result.languages.map((language) => ({
      label: language.name,
      value: language.id,
    })),
    creatorNiches: response.result.ideal_creator_niches.map((niche) => ({
      value: niche,
      label: niche,
    })),
    followersCounts: response.result.minimum_follower_counts,
    engagementRates: response.result.minimum_engagement_rates,
  };
}

export function transformCampaignFormData(
  campaignFormData: types.CampaignInfoFormData,
  { objectives }: { objectives: types.CampaignObjective[] }
) {
  const {
    campaignEndDate,
    campaignStartDate,
    description,
    goal,
    title,
    typeOfCampaign,
  } = campaignFormData;
  const objective = objectives.find((objective) => objective.value === goal);
  return {
    title,
    description,
    ...(campaignStartDate && { start_date: campaignStartDate }),
    ...(campaignEndDate && { end_date: campaignEndDate }),
    ...(objective && { objectives: [objective] }),
    ...(typeOfCampaign && { campaign_type: typeOfCampaign }),
  };
}

export function transformAudienceFormData(
  audienceFormData: types.AudienceFormData,
  {
    languages,
    locations,
  }: {
    languages: types.CampaignLanguage[];
    locations: types.CampaignLocation[];
  }
) {
  const targetAudience = {
    ...(audienceFormData.ageRange?.length && {
      age_range: audienceFormData.ageRange,
    }),
    ...(audienceFormData.gender && { gender: audienceFormData.gender }),
    ...(audienceFormData.language?.length && {
      languages: audienceFormData.language.map((language) =>
        languages.find((lang) => lang.value === language)
      ),
    }),
    ...(audienceFormData.location?.length && {
      locations: audienceFormData.location.map((location) =>
        locations.find((loc) => loc.value === location)
      ),
    }),
  } as const;
  const eligibility = [
    audienceFormData.idealCreatorNiche && {
      key: "ideal_creator_niche",
      type: "string-array",
      value: audienceFormData.idealCreatorNiche,
    },
    audienceFormData.minimumFollowerCount && {
      key: "minimum_follower_count",
      type: "uint64",
      value: audienceFormData.minimumFollowerCount,
    },
    audienceFormData.minimumEngagementRate && {
      key: "minimum_engagement_rate",
      type: "float",
      value: audienceFormData.minimumEngagementRate,
    },
  ].filter(Boolean);
  return {
    ...(eligibility.length && { eligibility }),
    ...(Object.keys(targetAudience).length && {
      target_audience: targetAudience,
    }),
  } as const;
}

export function transformBudgetFormData(budgetFormData: types.BudgetFormData) {
  const deliverables = {
    ...(budgetFormData.noOfImages && {
      images: Number(budgetFormData.noOfImages),
    }),
    ...(budgetFormData.numberOfStories && {
      stories: Number(budgetFormData.numberOfStories),
    }),
    ...(budgetFormData.numberOfReels && {
      reels: Number(budgetFormData.numberOfReels),
    }),
  };
  return {
    ...(budgetFormData.totalCampaignBudget && {
      budget: Number(budgetFormData.totalCampaignBudget),
    }),
    ...(budgetFormData.totalCampaignBudget &&
      budgetFormData.costPerInfluencer && {
        total_slots: Math.floor(
          Number(budgetFormData.totalCampaignBudget) /
            Number(budgetFormData.costPerInfluencer)
        ),
      }),
    ...(Object.keys(deliverables).length && { deliverables }),
  };
}
