import { useMutation } from "@tanstack/react-query";
import {
  Button,
  ButtonLikeLink,
  Form,
  FormItem,
  Input,
  Text,
} from "../../Components";
import { FormWrapper, ResetRight } from "./ForgotPassword.styles";
import * as types from "./ForgotPassword.types";
import * as services from "./ForgotPassword.services";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { AUTH } from "../../Constants";

export default function ForgotPassword() {
  const { token } = useParams();
  const resetPasswordMutation = useMutation({
    mutationFn: services.postResetPassword,
  });

  async function handleResetPassword(formData: types.ForgotPasswordFormData) {
    resetPasswordMutation.mutate(formData);
  }

  useEffect(() => {
    if (!token) {
      return;
    }
    localStorage.setItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY, token);
  }, [token]);

  return (
    <ResetRight>
      <Text fontWeight="SEMI_BOLD" fontSize="XXX_LARGE" as="h1" lineHeight="1">
        Reset Password
      </Text>
      {resetPasswordMutation.isSuccess ? (
        <div>
          <Text color="SECONDARY_TEXT" fontSize="LARGE">
            Password reset successfully
          </Text>
          <FormWrapper>
            <ButtonLikeLink to="/login" text="Login" />
          </FormWrapper>
        </div>
      ) : (
        <Text color="SECONDARY_TEXT">Please enter your new password below</Text>
      )}
      {resetPasswordMutation.isSuccess ? null : (
        <FormWrapper>
          <Form<types.ForgotPasswordFormData> onSubmit={handleResetPassword}>
            <FormItem
              label={<Text>Password</Text>}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input
                placeholder="eg. xyz@gmail.com"
                type="password"
                autoFocus
              />
            </FormItem>
            <FormItem
              label={<Text>Confirm Password</Text>}
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input placeholder="eg. xyz@gmail.com" type="password" />
            </FormItem>
            <FormItem>
              <Button
                text="Reset Password"
                fullWidth
                type="primary"
                size="large"
                htmlType="submit"
                loading={resetPasswordMutation.isPending}
              />
            </FormItem>
          </Form>
        </FormWrapper>
      )}
    </ResetRight>
  );
}
