import Hls from "hls.js";
import { useEffect, useRef, useState } from "react";

export function useHlsPlayer() {
  const hlsRef = useRef<any>(null);
  const [hasHlsRefSet, setHasHlsRefSet] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      return;
    }
    hlsRef.current = new Hls();
    setHasHlsRefSet(true);
  }, []);
  useEffect(() => {
    if (!hasHlsRefSet || !videoRef.current || !hlsRef.current) {
      return;
    }
    const videoElement = videoRef.current;
    hlsRef.current.loadSource(videoElement.src);
    hlsRef.current.attachMedia(videoElement);
  }, [hasHlsRefSet]);
  return { videoRef, hlsRef };
}
