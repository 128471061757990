import { format, formatRelative } from "date-fns";

export function formatDayMonthYear(dateTime: string) {
  if (!dateTime) return "";
  return format(new Date(dateTime), "dd/MM/yyyy");
}

export function formatDayMonthYearTime(dateTime: string) {
  if (!dateTime) return "";
  return format(new Date(dateTime), "dd/MM/yyyy HH:mm");
}

/**
 *  Function will take in seconds and convert it to a readable format
 * @param seconds
 * @returns string
 */

export function formatSecondsToTime(seconds: number) {
  if (!seconds) return "0:00";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
  }`;
}

/**
 * Function will take in date and time and convert it to relative time
 * @param dateTime
 * @returns string
 */

export function formatRelativeTime(dateTime: string): string {
  if (!dateTime) return "";
  return formatRelative(dateTime, new Date());
}
