import { ButtonLikeLink, Text } from "../../Components";
import {
  VerifyEmailActions,
  VerifyEmailContainer,
  VerifyEmailContent,
  VerifyEmailImage,
} from "./VerifyEmail.styles";
import EmailIllustration from "../../Assets/Images/email_illustrations.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { postExchangeToken } from "./VerifyEmail.services";
import { AUTH } from "../../Constants";

function VerifyEmail() {
  const { token } = useParams();
  const navigate = useNavigate();
  const { mutateAsync } = useMutation({
    mutationFn: postExchangeToken,
    onSuccess: (res) => {
      if (!res.result.long_lived_token) {
        return;
      }
      localStorage.setItem(
        AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY,
        res.result.long_lived_token
      );
      navigate("/");
    },
  });

  useEffect(() => {
    if (!token) {
      return;
    }
    mutateAsync(token);
  }, [mutateAsync, token]);

  return (
    <VerifyEmailContainer>
      <VerifyEmailContent>
        <VerifyEmailImage src={EmailIllustration} alt="Email" />
        <Text
          lineHeight="1"
          fontSize="LARGE"
          fontWeight="SEMI_BOLD"
          textAlign="CENTER"
        >
          Verify your email address
        </Text>
        <Text
          lineHeight="1"
          color="SECONDARY_TEXT"
          textAlign="CENTER"
          fontSize="X_SMALL"
        >
          You’re just one step away from using Ryme, verify your email address
        </Text>
        <VerifyEmailActions>
          <ButtonLikeLink to="/" text="Go to Home" />
        </VerifyEmailActions>
      </VerifyEmailContent>
    </VerifyEmailContainer>
  );
}

export default VerifyEmail;
