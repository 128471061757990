import { Link, Outlet, useNavigate } from "react-router-dom";
import {
  BrandContent,
  BrandLogo,
  BrandNavigation,
  BrandWrapper,
} from "./Brand.styles";
import { Avatar, Menu } from "../../Components";
import { AUTH } from "../../Constants";
import { useEffect } from "react";
import { MENU_ITEMS } from "./Brand.constants";
import LogoImage from "../../Assets/Images/logo.png";

function Brand() {
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.setItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY, "");
    navigate("/login");
  }
  useEffect(() => {
    const token = localStorage.getItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY);
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  function handleMenuClick(key?: number | bigint | string | undefined) {
    if (key === "logout") {
      handleLogout();
      return;
    }
    const menuItem = MENU_ITEMS.find((item) => item.key === key);
    navigate(menuItem?.path || "/");
  }

  return (
    <BrandWrapper>
      <BrandNavigation>
        <Link to="/">
          <BrandLogo src={LogoImage} alt="Ryme Logo" />
        </Link>
        <Menu
          items={MENU_ITEMS.map((item) => ({
            label: item.label,
            key: item.key,
            icon: <item.Icon />,
          }))}
          onClick={(info) => handleMenuClick(info?.key)}
        />
        <Avatar alt="U" src="" size={32} />
      </BrandNavigation>
      <BrandContent>
        <Outlet />
      </BrandContent>
    </BrandWrapper>
  );
}

export default Brand;
