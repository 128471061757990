import { useQuery } from "@tanstack/react-query";
import {
  ActionButtons,
  ApplicantProfileContext,
  ApplicationsList,
} from "./BrandApplications.HelperComponents";
import {
  ApplicantProfileWrapper,
  ApplicationTabs,
  PageContent,
  PageTitle,
} from "./BrandApplications.styles";
import * as services from "./BrandApplications.services";
import { useParams, useSearchParams } from "react-router-dom";
import * as CONSTANTS from "./BrandApplications.constants";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";

function BrandApplications() {
  const { campaignId } = useParams();
  const updateQueryParams = useUpdateQueryParams();
  const [searchParams] = useSearchParams();
  const activeTab =
    searchParams.get("tab") || CONSTANTS.APPLICATION_STATUS.APPLIED;

  const { data } = useQuery({
    queryKey: ["campaignApplications", campaignId, activeTab],
    queryFn: () =>
      services.getCampaignApplications(campaignId || "", activeTab),
  });

  if (!data?.result) {
    return null;
  }

  return (
    <main>
      <PageTitle fontSize="X_LARGE" fontWeight="SEMI_BOLD">
        Applications
        {data?.result.campaign_title ? ` “${data.result.campaign_title}”` : ""}
      </PageTitle>
      {campaignId ? (
        <ApplicationTabs
          onChange={(activeKey) => updateQueryParams({ tab: activeKey })}
          items={[
            {
              key: CONSTANTS.APPLICATION_STATUS.APPLIED,
              label: "Applied",
              children: (
                <PageContent>
                  <ApplicationsList
                    campaignId={campaignId}
                    status={CONSTANTS.APPLICATION_STATUS.APPLIED}
                  />
                  <ApplicantProfileWrapper>
                    <ApplicantProfileContext />
                    <ActionButtons campaignId={campaignId} showSave={false} />
                  </ApplicantProfileWrapper>
                </PageContent>
              ),
            },

            {
              key: CONSTANTS.APPLICATION_STATUS.ACCEPTED,
              label: "Accepted",
              children: (
                <PageContent>
                  <ApplicationsList
                    campaignId={campaignId}
                    status={CONSTANTS.APPLICATION_STATUS.ACCEPTED}
                  />
                  <ApplicantProfileWrapper>
                    <ApplicantProfileContext />
                  </ApplicantProfileWrapper>
                </PageContent>
              ),
            },
            {
              key: CONSTANTS.APPLICATION_STATUS.REJECTED,
              label: "Rejected",
              children: (
                <PageContent>
                  <ApplicationsList
                    campaignId={campaignId}
                    status={CONSTANTS.APPLICATION_STATUS.REJECTED}
                  />
                  <ApplicantProfileWrapper>
                    <ApplicantProfileContext />
                  </ApplicantProfileWrapper>
                </PageContent>
              ),
            },
          ]}
          defaultActiveKey={activeTab || CONSTANTS.APPLICATION_STATUS.APPLIED}
        />
      ) : null}
    </main>
  );
}

export default BrandApplications;
