import styled from "styled-components";
import { Text } from "../../Components";
import { STYLES } from "../../Constants";

export const BrandProposalWrapper = styled.main`
  width: 800px;
  margin: 0 auto;
`;

export const PageTitle = styled(Text)`
  padding: 16px;
`;

export const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StepsContent = styled.div`
  width: 800px;
`;

export const CampaignDetailsWrapper = styled.div`
  padding: 16px;
`;

export const FormWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 56px;
`;

export const ActionFooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  background-color: ${STYLES.COLORS.WHITE};
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  border-top: 1px solid ${STYLES.COLORS.BORDER_COLOR};
`;

export const StepTitle = styled(Text)`
  width: max-content;
`;

export const SectionWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${STYLES.COLORS.BORDER_COLOR};
`;

export const ReferenceItemWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const RulesWrapper = styled.div`
  padding-top: 12px;
`;
