import styled, { css } from "styled-components";
import * as types from "./Tag.types";
import Text from "../Text";
import * as CONSTANTS from "./Tag.constants";

export const TagWrapper = styled(Text)<{
  $color: types.TagProps["color"];
  $shape: types.TagProps["shape"];
}>`
  &&& {
    color: ${({ $color }) => CONSTANTS.TAG_COLORS[$color].TEXT_COLOR};
    background-color: ${({ $color }) =>
      CONSTANTS.TAG_COLORS[$color].BACKGROUND_COLOR};
  }
  ${({ $shape }) =>
    $shape === "circle"
      ? css`
          border-radius: 9999px;
          padding: 4px 8px;
        `
      : css`
          border-radius: 4px;
          padding: 4px;
        `}
`;
