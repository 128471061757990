import { ButtonLikeLink, Dropdown, Table, Text } from "../../Components";
import {
  ActionWrapper,
  BrandCampaignsWrapper,
  DropdownContent,
  TitleWrapper,
} from "./BrandCampaigns.styles";
import * as CONSTANTS from "./BrandCampaigns.constants";
import * as services from "./BrandCampaigns.services";
import { useQuery } from "@tanstack/react-query";
import * as utilities from "./BrandCampaigns.utilities";
import * as types from "./BrandCampaigns.types";
import { PrimaryAction } from "./BrandCampaigns.HelperComponents";
import DashOutlined from "../../Assets/Icons/DashOutlined";
import { useUpdateCampaignStatus } from "./BrandCampaigns.hooks";
import { useNavigate } from "react-router-dom";

function BrandCampaigns() {
  const { data, isPending } = useQuery({
    queryKey: ["brandCampaigns"],
    queryFn: () => services.getCampaigns(),
    select: utilities.transformMyCampaigns,
  });

  const navigate = useNavigate();
  const { updateCampaignCreateStatus } = useUpdateCampaignStatus();

  function handleSecondaryActionClick(
    record: types.TransformedCampaign,
    key?: number | bigint | string | undefined
  ) {
    const action = record.actions?.secondary?.find(
      (action) => action.cta === key
    );
    if (!action) {
      return;
    }
    if (action.onclick.path) {
      navigate(action.onclick.path);
      return;
    }
    if (action.onclick.url) {
      window.open(
        action.onclick.url,
        action.onclick.new_tab ? "_blank" : "_self"
      );
      return;
    }
    if (action.onclick.status_update) {
      updateCampaignCreateStatus({
        campaignId: record.id,
        status: action.onclick.status_update,
      });
    }
  }

  const tableColumns = CONSTANTS.COLUMNS.map((column) => {
    if (column.key !== "actions") {
      return column;
    }
    return {
      ...column,
      render: (text: unknown, record: types.TransformedCampaign) => {
        if (!record.actions) {
          return null;
        }
        return (
          <ActionWrapper>
            <PrimaryAction
              action={record.actions.primary}
              campaignId={record.id}
            />
            {record.actions.secondary ? (
              <Dropdown
                items={record.actions.secondary.map((action) => ({
                  label: action.label,
                  key: action.cta,
                }))}
                onClick={(info) => {
                  handleSecondaryActionClick(record, info?.key);
                }}
              >
                <DropdownContent>
                  <DashOutlined />
                </DropdownContent>
              </Dropdown>
            ) : null}
          </ActionWrapper>
        );
      },
    };
  });
  return (
    <BrandCampaignsWrapper>
      <TitleWrapper>
        <Text as="h1" fontWeight="SEMI_BOLD" fontSize="LARGE">
          My Campaigns
        </Text>
        <ButtonLikeLink
          to="/brand/create-campaign"
          text="Create Campaign"
        ></ButtonLikeLink>
      </TitleWrapper>
      <Table
        columns={tableColumns}
        rowKey="id"
        data={data?.campaigns || []}
        loading={isPending}
      />
    </BrandCampaignsWrapper>
  );
}

export default BrandCampaigns;
