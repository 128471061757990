import * as types from "./BrandSubmissions.types";
import * as CONSTANTS from "./BrandSubmissions.constants";
import { number } from "../../Utilities";

function formatApplicationInsight(insight: types.SubmissionInsight) {
  switch (insight.key) {
    case CONSTANTS.INSIGHT_TYPES.ENGAGEMENT_RATE:
      return `> ${insight.value}%`;
    case CONSTANTS.INSIGHT_TYPES.FOLLOWER_COUNT:
      return number.abbreviateNumber(Number(insight.value));
    default:
      return insight.value;
  }
}

export function transformSubmittedApplications(
  params: types.SubmissionsRawResponse
) {
  return {
    campaignId: params.result.campaign_id,
    campaignTitle: params.result.campaign_title,
    submissions: params.result.submissions.map((submission) => ({
      id: submission.id,
      application_id: submission.application_id,
      tag: submission.tag,
      partner: submission.partner,
      insights: submission.insights.map((insight) => ({
        key: insight.key,
        label: insight.label,
        value: formatApplicationInsight(insight),
      })),
      next_action: submission.next_action,
      rejectionReasons: submission.rejectionReasons,
      message: submission.message,
    })),
  };
}

export function transformApplicationMeta(
  response: types.ApplicationMetaRawResponse
) {
  if (!response?.result) {
    return {
      rejectionReasons: [],
      wrongPostReasons: [],
    };
  }
  return {
    rejectionReasons: response.result.rejection_reasons.map((reason) => ({
      label: reason.title,
      value: reason.title,
      description: reason.description,
    })),
    wrongPostReasons: response.result.wrong_post_reasons.map((reason) => ({
      label: reason,
      value: reason,
    })),
  };
}
