import styled from "styled-components";
import {} from "../../";
import { Text } from "../../Components";
import { STYLES } from "../../Constants";

export const LoginWrapper = styled.div`
  padding-top: 100px;
  max-width: 400px;
  margin: 0 auto;
`;

export const EmailFormWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
`;

export const UserTextInfoWrapper = styled.div`
  padding-left: 12px;
`;

export const GoogleLoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SeperatorText = styled(Text)`
  margin-bottom: 40px;
  position: relative;
  width: 100%;
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 2ch);
    height: 1px;
    background: ${STYLES.COLORS.SECONDARY_TEXT};
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
`;
