import { Checkbox as AntdCheckbox } from "antd";
import * as types from "./CheckboxGroup.types";

function Checkbox({ children, onChange, name }: types.CheckboxGroupProps) {
  return (
    <AntdCheckbox.Group onChange={onChange} name={name}>
      {children}
    </AntdCheckbox.Group>
  );
}

export default Checkbox;
