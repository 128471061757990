import { Checkbox as AntdCheckbox } from "antd";
import * as types from "./Checkbox.types";

function Checkbox({ children, onChange, value }: types.CheckboxProps) {
  return (
    <AntdCheckbox onChange={onChange} value={value}>
      {children}
    </AntdCheckbox>
  );
}

export default Checkbox;
