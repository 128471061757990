import * as types from "./ForgotPassword.types";
import * as CONSTANTS from "./ForgotPassword.constants";
import { http } from "../../Utilities";

export function postResetPassword(
  resetPasswordData: types.ForgotPasswordFormData
) {
  return http.put<types.UpdatePasswordResponse>(
    CONSTANTS.API_ENDPOINTS.RESET_PASSWORD,
    { updates: resetPasswordData }
  );
}
