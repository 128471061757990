import CommentOutlined from "../../Assets/Icons/CommentOutlined";
import HeartOutlined from "../../Assets/Icons/HeartOutlined";
import SaveOutlined from "../../Assets/Icons/SaveOutlined";
import ShareAltOutlined from "../../Assets/Icons/ShareAltOutlined";
import { dateTime, number } from "../../Utilities";
import * as types from "./BrandInsights.types";

function formatAdditionalInfo(params: types.AdditionalInfo) {
  switch (params.key) {
    case "followers_count":
      return number.abbreviateNumber(Number(params.value));

    case "target_audience_match":
      return `${params.value}%`;

    case "applied_on":
    case "submitted_on":
      return dateTime.dateTime.formatDayMonthYear(params.value);
    default:
      return params.value;
  }
}

export function transformCampaignInsight(
  response: types.CampaignInsightRawResponse
) {
  const {
    result: { campaign, socials, partner, additional },
  } = response;

  const campaignTransformed: types.Campaign = {
    id: campaign.id,
    title: campaign.title || "This campaign has no title",
    description: campaign.description || "This campaign has no description",
    lastUpdated: dateTime.dateTime.formatDayMonthYearTime(
      socials[0].last_updated_on
    ),
    status: campaign.status,
    campaignMeta: [
      {
        label: "Campaign Goal",
        value: campaign.goals.map((goal) => goal.title).join(", "),
      },
      {
        label: "Content Type",
        value: "Reels",
      },
      {
        label: "Target audience",
        value: `${
          campaign.target_audience.gender
        }, ${campaign.target_audience.age_ranges.join(", ")}`,
      },
      {
        label: "Budget",
        value: campaign.budget.toString(),
      },
    ],
  };

  return {
    campaign: campaignTransformed,
    insights: socials[0].insights,
    topPerformingSubmissions: socials[0].top_performing_submissions,
    partner: partner,
    additionalInfo: additional?.map((info) => ({
      label: info.label,
      value: formatAdditionalInfo(info),
      key: info.key,
    })),
    submission: response.result.submission,
  };
}

export function getIcons(type: string) {
  switch (type) {
    case "likes":
      return <HeartOutlined />;
    case "comments":
      return <CommentOutlined />;
    case "shares":
      return <ShareAltOutlined />;
    case "saves":
      return <SaveOutlined />;
    default:
      return null;
  }
}
