import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as services from "./BrandCampaigns.services";

export function useUpdateCampaignStatus() {
  const queryClient = useQueryClient();
  const { mutateAsync: updateCampaignCreateStatus, isPending } = useMutation({
    mutationFn: services.updateCampaignCreateStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["brandCampaigns"] });
    },
  });
  return {
    updateCampaignCreateStatus,
    isPending,
  };
}
