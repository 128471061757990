export const API_ENDPOINTS = {
  RECEIVED_SUBMISSIONS: "/client/brand/received_submissions",
  SUBMISSION: "/submission",
  SUBMISSIONS: "/submissions",
  FEEDBACK: "/feedback",
  COMMENT: "/comment",
  APPLICATION_META: "/meta/view-applications",
} as const;

export const SUBMISSION_STATUS = {
  SUBMITTED: "Submitted",
  REJECTED: "Rejected",
  APPROVED: "Approved",
  VALIDATED: "Validated",
  CHANGE_REQUESTED: "Change Requested",
  POST_UPLOADED: "Post Uploaded",
  POST_VERIFIED: "Post Verified",
  POST_VERIFICATION_FAILED: "Post Verification Failed",
  TIMELINE_EXPIRED: "Timeline Expired",
} as const;

export const TABS = {
  IN_PROGRESS: "In-Progress",
  APPROVED: "Approved",
  REJECTED: "Rejected",
} as const;

export const STATUS_MAP_TABS = {
  [TABS.IN_PROGRESS]: [SUBMISSION_STATUS.VALIDATED],
  [TABS.APPROVED]: [SUBMISSION_STATUS.APPROVED],
  [TABS.REJECTED]: [
    SUBMISSION_STATUS.REJECTED,
    SUBMISSION_STATUS.TIMELINE_EXPIRED,
  ],
};

export const MODAL_TYPES = {
  APPROVE: "Approve",
  REJECT: "Reject",
  REQUEST_CHANGES: "Request Changes",
} as const;

export const INSIGHT_TYPES = {
  FOLLOWER_COUNT: "follower_count",
  ENGAGEMENT_RATE: "engagement_rate",
  POST_COUNT: "post_count",
} as const;

export const CAMPAIGN_SUBMISSION_QUERY_KEY = "campaignSubmissions";
export const CAMPAIGN_SUBMISSION_PROFILE_QUERY_KEY = "campaignSubmissions";
