import * as types from "./BrandApplications.types";
import * as CONSTANTS from "./BrandApplications.constants";
import { number } from "../../Utilities";

function formatApplicationInsight(insight: types.ApplicantListInsight) {
  switch (insight.key) {
    case CONSTANTS.INSIGHT_TYPES.ENGAGEMENT_RATE:
      return `> ${insight.value}%`;
    case CONSTANTS.INSIGHT_TYPES.FOLLOWER_COUNT:
      return number.abbreviateNumber(Number(insight.value));
    default:
      return insight.value;
  }
}

export function transformApplicationList(
  response: types.ApplicationListRawResponse
) {
  return {
    campaignTitle: response.result.campaign_title,
    campaignId: response.result.campaign_id,
    applications: response.result.applications.map((application) => ({
      id: application.id,
      insights: application.insights.map((insight) => ({
        key: insight.key,
        label: insight.label,
        value: formatApplicationInsight(insight),
      })),
      partner: application.partner,
      partner_id: application.partner_id,
      tag: application.tag,
    })),
  };
}
