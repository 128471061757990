import AppRouter from "./Router";
import { ConfigProvider } from "antd";
import { STYLES } from "./Constants";
import { GlobalStyle } from "./App.styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

function App() {
  return (
    <ConfigProvider theme={STYLES.BASE_THEME}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <AppRouter />
      </QueryClientProvider>
      <GlobalStyle />
    </ConfigProvider>
  );
}

export default App;
