import styled from "styled-components";

export const VerifyEmailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const VerifyEmailContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 300px;
`;

export const VerifyEmailImage = styled.img`
  width: 150px;
`;

export const VerifyEmailActions = styled.div`
  padding-top: 16px;
`;
