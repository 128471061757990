import styled from "styled-components";
import { STYLES } from "../../Constants";

export const BrandCampaignFormWrapper = styled.div`
  max-width: 750px;
  padding-top: 20px;
`;

export const FormContainer = styled.main`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${STYLES.COLORS.WHITE};
  padding: 20px;
  border-radius: 8px;
`;

export const CampaignDatePickerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

export const CtaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SaveActionWrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
`;

export const DeliverablesWrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
`;
