export function validatePassword(password: string): string | null {
  const errors: string[] = [];

  if (password.length < 8) {
    errors.push("at least 8 characters long");
  }
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    errors.push("at least one special character");
  }
  if (!/\d/.test(password)) {
    errors.push("at least one digit");
  }
  if (!/[a-z]/.test(password)) {
    errors.push("at least one lowercase letter");
  }
  if (!/[A-Z]/.test(password)) {
    errors.push("at least one uppercase letter");
  }

  if (errors.length > 0) {
    return `Password must contain ${errors.join(", ")}.`;
  }

  return null; // No errors, valid password
}
