import { http } from "../../Utilities";
import { url } from "../../Utilities/Url";
import * as CONSTANTS from "./BrandProposal.constants";
import * as types from "./BrandProposal.types";

export function getCampaignProposal(campaignId: string) {
  const urlToCall = url.replacePathParams(CONSTANTS.API_ENDPOINTS.CAMPAIGN, {
    campaignId,
  });
  return http.get<types.CampaignApiRawResponse>(urlToCall);
}

export function updateCampaign({
  id,
  title,
  description,
  otherUpdates,
}: {
  id: number;
  title?: string;
  description?: string;
  //TODO: fix types here.
  otherUpdates?: Record<string, any>;
}) {
  const updatesToApply = {
    ...(title && { title }),
    ...(description && { description }),
    ...(otherUpdates && { ...otherUpdates }),
  };
  return http.put<types.UpdateCampaignResponse>(
    CONSTANTS.API_ENDPOINTS.UPDATE_CAMPAIGN,
    { id: id, updates: updatesToApply }
  );
}

export function campaignProposalMeta() {
  return http.get<types.CampaignProposalMetaResponse>(
    CONSTANTS.API_ENDPOINTS.PROPOSAL_META
  );
}
