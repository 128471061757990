import { Link } from "./ButtonLikeLink.styles";
import * as types from "./ButtonLikeLink.types";
import * as CONSTANTS from "./ButtonLikeLink.constants";

export default function ButtonLikeLink({
  to,
  text,
  type = CONSTANTS.BUTTON_TYPES.BUTTON,
  target,
  align,
  noPadding,
}: types.ButtonLikeLinkProps) {
  return (
    <Link
      to={to}
      $type={type}
      target={target}
      $align={align}
      $noPadding={noPadding}
    >
      {text}
    </Link>
  );
}
