import styled from "styled-components";

export const BrandWrapper = styled.div`
  --header-height: 48px;
  display: flex;
  min-height: 100vh;
`;

export const BrandNavigation = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #c8ced7;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const BrandLogo = styled.img`
  width: 32px;
`;

export const BrandContent = styled.div`
  width: 100%;
`;
