import { useQuery } from "@tanstack/react-query";
import { Text } from "../../Components";
import {
  AudienceForm,
  BudgetForm,
  CampaignInfoForm,
} from "./BrandCampaignForm.HelperComponents";
import {
  BrandCampaignFormWrapper,
  FormContainer,
} from "./BrandCampaignForm.styles";
import * as services from "./BrandCampaignForm.services";
import * as utilities from "./BrandCampaignForm.utilities";
import { useSearchParams } from "react-router-dom";

function BrandCampaignForm() {
  const { data } = useQuery({
    queryKey: ["campaignMeta"],
    queryFn: services.getCampaignMeta,
    select: utilities.transformCampaignMeta,
  });
  const [searchParams] = useSearchParams();
  const step = Number(searchParams.get("step") || 1);
  function getForm() {
    if (!data) {
      return null;
    }
    switch (step) {
      case 1:
        return (
          <CampaignInfoForm objectives={data.objectives} types={data.types} />
        );
      case 2:
        return (
          <AudienceForm
            locations={data.locations}
            ages={data.ages}
            genders={data.genders}
            languages={data.languages}
            creatorNiches={data.creatorNiches}
            followersCounts={data.followersCounts}
            engagementRates={data.engagementRates}
          />
        );
      case 3:
        return <BudgetForm />;

      default:
        return null;
    }
  }
  return (
    <BrandCampaignFormWrapper>
      <Text as="h1" fontSize="XXX_LARGE" fontWeight="SEMI_BOLD">
        Create Campaign
      </Text>
      <div style={{ height: "75px", backgroundColor: "lightgray" }}></div>
      <FormContainer>{getForm()}</FormContainer>
    </BrandCampaignFormWrapper>
  );
}

export default BrandCampaignForm;
