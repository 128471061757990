import { Select as AntdSelect } from "antd";
import * as types from "./Select.types";

function Select({
  options,
  mode,
  onChange,
  optionRenderer,
  placeholder,
  value,
  disabled,
}: types.SelectProps) {
  return (
    <AntdSelect
      options={options}
      mode={mode}
      onChange={onChange}
      style={{ width: "100%" }}
      optionRender={optionRenderer}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
    />
  );
}

export default Select;
