import styled, { css } from "styled-components";
import { Button as UiButton } from "antd";
import * as types from "./Button.types";

export const StyledButton = styled(UiButton)<types.ButtonStyledProps>`
  ${(props) =>
    props.$noPadding
      ? css`
          &&& {
            padding: 0;
          }
        `
      : ""};
`;
