import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Login from "../Pages/Login";
import ForgotPassword from "../Pages/ForgotPassword";
import RequestForgotPassword from "../Pages/RequestForgotPassword";
import Brand from "../Pages/Brand";
import BrandCampaignForm from "../Pages/BrandCampaignForm";
import CreateCampaign from "../Pages/CreateCampaign";
import BrandApplications from "../Pages/BrandApplications";
import BrandCampaigns from "../Pages/BrandCampaigns";
import BrandSubmissions from "../Pages/BrandSubmissions";
import BrandProposal from "../Pages/BrandProposal";
import BrandInsights from "../Pages/BrandInsights";
import VerifyEmail from "../Pages/VerifyEmail";
import { AUTH } from "../Constants";
import {
  AuthLayoutWrapper,
  LayoutHelperText,
  LayoutHeroImage,
  LayoutLeft,
  LayoutRight,
  LogoImage,
} from "./Router.styles";
import Logo from "../Assets/Images/logo.png";
import HorizontalLogo from "../Assets/Images/logo_horizontal.png";
import { Text } from "../Components";

function HomeRedirect() {
  const token = localStorage.getItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY);
  return <Navigate to={token ? "/brand/campaigns" : "/login"} />;
}

function AuthLayout() {
  return (
    <AuthLayoutWrapper>
      <LayoutLeft>
        <LayoutHeroImage src={Logo} alt="Ryme Logo" />
        <Text fontSize="XXX_LARGE" fontWeight="SEMI_BOLD" lineHeight="1">
          Unleash authentic brand storytelling with ryme.ai
        </Text>
        <LayoutHelperText fontWeight="SEMI_BOLD">
          Login to find your perfect influencer match.
        </LayoutHelperText>
      </LayoutLeft>
      <LayoutRight>
        <LogoImage src={HorizontalLogo} />
        <Outlet />
      </LayoutRight>
    </AuthLayoutWrapper>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <HomeRedirect />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <RequestForgotPassword />,
      },
      {
        path: "/forgot-password/:token",
        element: <ForgotPassword />,
      },
      {
        path: "/verify-email/:token?",
        element: <VerifyEmail />,
      },
    ],
  },
  {
    path: "/brand/",
    element: <Brand />,
    children: [
      {
        path: "/brand/form",
        element: <BrandCampaignForm />,
      },
      {
        path: "/brand/create-campaign",
        element: <CreateCampaign />,
      },
      {
        path: "/brand/campaigns",
        element: <BrandCampaigns />,
      },
      {
        path: "/brand/campaigns/:campaignId/applications",
        element: <BrandApplications />,
      },
      {
        path: "/brand/campaigns/:campaignId/submissions",
        element: <BrandSubmissions />,
      },
      {
        path: "/brand/campaigns/:campaignId/proposal",
        element: <BrandProposal />,
      },
      {
        path: "/brand/campaigns/:campaignId/insights",
        element: <BrandInsights />,
      },
    ],
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
