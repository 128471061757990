import { Tabs } from "antd";
import styled from "styled-components";

export const StyledTabs = styled(Tabs)`
  &&& {
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
`;
