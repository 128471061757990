import { http } from "../../Utilities";
import * as CONSTANTS from "./BrandCampaignForm.constants";
import * as types from "./BrandCampaignForm.types";

export function getCampaignMeta() {
  return http.get<types.CampaignMetaRawResponse>(
    CONSTANTS.API_ENDPOINTS.CAMPAIGN_META
  );
}

export function postCreateCampaign(data: any) {
  return http.post<types.CreateCampaignResponse>(
    CONSTANTS.API_ENDPOINTS.CAMPAIGN,
    data
  );
}

export function updateCampaign({ id, updates }: { id: string; updates: any }) {
  return http.put<types.UpdateCampaignResponse>(
    CONSTANTS.API_ENDPOINTS.CAMPAIGN,
    { ids: [Number(id)], updates }
  );
}
