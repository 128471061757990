export const FONT_SIZE = {
  XX_SMALL: 10,
  X_SMALL: 12,
  SMALL: 14,
  MEDIUM: 16,
  LARGE: 20,
  X_LARGE: 24,
  XX_LARGE: 28,
  XXX_LARGE: 32,
};

export const FONT_WEIGHT = {
  REGULAR: 400,
  SEMI_BOLD: 600,
};

export const TEXT_ALIGN = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
};
