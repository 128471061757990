import styled from "styled-components";
import { STYLES } from "../../Constants";
import { Text } from "../../Components";

export const BrandInsightsWrapper = styled.div`
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
`;

export const BrandInsightsHeader = styled.div`
  background-color: ${STYLES.COLORS.WHITE};
  padding: 12px;
`;

export const BaseCard = styled.div`
  background-color: ${STYLES.COLORS.WHITE};
  border-radius: 8px;
  padding: 16px;
`;

export const TagWrapper = styled.div`
  width: fit-content;
`;

export const TitleWrapper = styled.div`
  padding-top: 32px;
`;

export const CampaignStatusCardWrapper = styled(BaseCard)`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 16px;
`;

export const CampaignHighlightsCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const CampaignHighlightsInfo = styled.div`
  padding: 12px;
`;

export const InsightSectionWrapper = styled.div`
  margin: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InsightCardWrapper = styled(BaseCard)``;

export const InsightCardContent = styled.div`
  margin-top: 24px;
`;

export const InsightTopSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const DonutChartWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChartLegendWrapper = styled.div`
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MainChartLegend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainDonutChartWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
`;

export const LegendTitle = styled(Text)`
  padding-left: 8px;
`;

export const ChartLegend = styled.div<{ $color: string }>`
  display: flex;
  gap: 4px;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    position: absolute;
    background-color: ${({ $color }) => $color};
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const MetricWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
`;

export const MetricContent = styled.div`
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  padding: 12px;
  border-radius: 8px;
  align-self: end;
`;

export const DistributionsWrapper = styled.div`
  padding-top: 24px;
`;

export const DistributionsChartWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
`;

export const DistributionPieChartWrapper = styled.div`
  margin-top: 24px;
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  padding: 12px;
  border-radius: 8px;
`;

export const TopPerformingSubmissionsWrapper = styled(BaseCard)``;

export const TopPerformingSubmissionScrollWrapper = styled.div`
  overflow-x: auto;
  max-width: calc(100vw - 140px);
`;

export const TopPerformingSubmissionsContent = styled.div`
  padding-top: 24px;
  display: flex;
  width: max-content;
  gap: 24px;
`;

export const TopPerformingSubmission = styled.div`
  flex: 0 0 auto;
  width: 250px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${STYLES.COLORS.SECONDARY_BACKGROUND_COLOR};
  padding: 12px;
  border-radius: 8px;
  gap: 12px;
`;

export const TopPerformingSubmissionMetric = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TopPerformingSubmissionMetricWrapper = styled(BaseCard)`
  margin-top: 12px;
`;

export const TopSubmissionsImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export const InfluencerDetailWrapper = styled(BaseCard)`
  flex: 1;
`;

export const InfluencerDetailContent = styled.div`
  display: grid;
  grid-template-columns: 160px 2fr 3fr;
  gap: 24px;
  padding-top: 24px;
`;

export const SecondaryInfoList = styled.ul`
  display: flex;
  gap: 12px;
  list-style: none;
  align-items: center;
  height: fit-content;
  padding-top: 12px;
  flex-wrap: wrap;
`;

export const SecondaryInfoWrapper = styled.li`
  position: relative;
  &:not(:last-child) {
    padding-right: 12px;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 1px;
      height: 50%;
      background-color: ${STYLES.COLORS.BORDER_COLOR};
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
`;

export const InfluencerDetailTopContent = styled.div`
  border-bottom: 1px solid ${STYLES.COLORS.BORDER_COLOR};
  padding-bottom: 12px;
`;

export const InfluencerDetailSection = styled.div`
  display: flex;
  gap: 24px;
`;

export const LivePostWrapper = styled.div`
  width: 300px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
`;

export const Gradient = styled.div`
  height: 50px;
  background: ${STYLES.COLORS.WHITE};
  background: linear-gradient(
    0deg,
    ${STYLES.COLORS.PRIMARY} 0%,
    transparent 100%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const BlurredImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  filter: blur(10px);
  border-radius: 8px;
`;

export const PostImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
`;

export const AdditionalInfoWrapper = styled(BaseCard)`
  background-color: ${STYLES.COLORS.PRIMARY_LIGHT_BACKGROUND_COLOR};
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const IndustryWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
`;

export const PostLinkWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
`;
