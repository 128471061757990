import {
  Button,
  ButtonLikeLink,
  DatePicker,
  Form,
  FormItem,
  Input,
  RadioGroup,
  Select,
  Text,
} from "../../Components";
import {
  CampaignDatePickerWrapper,
  CtaWrapper,
  DeliverablesWrapper,
  SaveActionWrapper,
} from "./BrandCampaignForm.styles";
import * as types from "./BrandCampaignForm.types";
import * as CONSTANTS from "./BrandCampaignForm.constants";
import { Form as AntdForm } from "antd";
import { useMutation } from "@tanstack/react-query";
import * as services from "./BrandCampaignForm.services";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import { useSearchParams } from "react-router-dom";
import * as utilities from "./BrandCampaignForm.utilities";

function FormActions({ onSaveAsDraft }: types.FormActionsProps) {
  return (
    <CtaWrapper>
      <ButtonLikeLink to="/" text="Cancel" type="LINK" />
      <SaveActionWrapper>
        <FormItem>
          <Button
            text="Save As Draft"
            fullWidth
            type="default"
            size="large"
            onClick={onSaveAsDraft}
          />
        </FormItem>
        <FormItem>
          <Button
            text="Next"
            fullWidth
            type="primary"
            size="large"
            htmlType="submit"
          />
        </FormItem>
      </SaveActionWrapper>
    </CtaWrapper>
  );
}

export function CampaignInfoForm({
  objectives,
  types,
}: types.CampaignInfoFormProps) {
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("id");

  const [form] = AntdForm.useForm();

  const { mutateAsync: createBrandCampaignAsync } = useMutation({
    mutationFn: services.postCreateCampaign,
  });

  const { mutateAsync: updateBrandCampaignAsync } = useMutation({
    mutationFn: services.updateCampaign,
  });

  const updateQueryParams = useUpdateQueryParams();

  async function handleCreateCampaign(data: types.CampaignInfoFormData) {
    const res = await createBrandCampaignAsync(data);
    if (res.success) {
      updateQueryParams({ id: res.result.id.toString(), step: "2" });
      return;
    }
  }

  async function handleUpdateCampaign(data: types.CampaignInfoFormData) {
    if (!campaignId) {
      // this condition is checked in handleSubmit, adding here to make typescript happy
      return;
    }
    const res = await updateBrandCampaignAsync({
      id: campaignId,
      updates: data,
    });
    if (res.success) {
      updateQueryParams({ step: "2" });
      return;
    }
  }

  function handleSubmit(data: types.CampaignInfoFormData) {
    const transformedData = utilities.transformCampaignFormData(data, {
      objectives,
    });
    if (!campaignId) {
      handleCreateCampaign(transformedData);
      return;
    }
    handleUpdateCampaign(transformedData);
  }

  function handleSaveAsDraft() {
    form.setFieldValue("action", "saveAsDraft");
    form.submit();
  }

  return (
    <Form onSubmit={handleSubmit} form={form}>
      <FormItem label="Campaign Title" name="title">
        <Input placeholder="Enter Title " />
      </FormItem>
      <FormItem label="Campaign Description" name="description">
        <Input
          type="textarea"
          placeholder="Enter Description"
          count={{ show: true, max: CONSTANTS.MAX_DESCRIPTION_LIMIT }}
        />
      </FormItem>
      <FormItem label="Campaign Goal" name="goal">
        <RadioGroup items={objectives} />
      </FormItem>
      <FormItem label="Type of Campaign" name="typeOfCampaign">
        <Select mode="multiple" options={types} />
      </FormItem>
      <CampaignDatePickerWrapper>
        <FormItem label="Campaign Start Date" name="campaignStartDate">
          <DatePicker />
        </FormItem>
        <FormItem label="Campaign End Date" name="campaignEndDate">
          <DatePicker />
        </FormItem>
      </CampaignDatePickerWrapper>
      <FormItem hidden name="action">
        <input type="hidden" />
      </FormItem>
      <FormActions onSaveAsDraft={handleSaveAsDraft} />
    </Form>
  );
}

export function AudienceForm({
  locations,
  ages,
  genders,
  languages,
  creatorNiches,
  followersCounts,
  engagementRates,
}: types.AudienceFormProps) {
  const [form] = AntdForm.useForm();

  const updateQueryParams = useUpdateQueryParams();
  const [searchParams] = useSearchParams();

  const campaignId = searchParams.get("id");

  const { mutateAsync: updateBrandCampaignAsync } = useMutation({
    mutationFn: services.updateCampaign,
  });

  async function handleSubmit(data: types.AudienceFormData) {
    if (!campaignId) {
      // this should never happen, but adding here to make typescript happy
      return;
    }
    const transformedData = utilities.transformAudienceFormData(data, {
      languages,
      locations,
    });

    const res = await updateBrandCampaignAsync({
      id: campaignId,
      updates: transformedData,
    });
    if (res.success) {
      updateQueryParams({ step: "3" });
      return;
    }
  }

  function handleSaveAsDraft() {
    form.setFieldValue("action", "saveAsDraft");
    form.submit();
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Text fontWeight="SEMI_BOLD" fontSize="LARGE">
        Brand Target Audience
      </Text>
      <FormItem label="Location" name="location">
        <Select mode="multiple" options={locations} />
      </FormItem>
      <FormItem label="Age Range" name="ageRange">
        <Select mode="multiple" options={ages} />
      </FormItem>
      <FormItem label="Gender" name="gender">
        <RadioGroup items={genders} />
      </FormItem>
      <FormItem label="Language" name="language">
        <Select mode="multiple" options={languages} />
      </FormItem>
      <Text fontWeight="SEMI_BOLD" fontSize="LARGE">
        Influencer Eligibility
      </Text>
      <FormItem label="Ideal Creator Niche" name="idealCreatorNiche">
        <Select options={creatorNiches} />
      </FormItem>
      <FormItem label="Minimum Follower Count" name="minimumFollowerCount">
        <RadioGroup items={followersCounts} />
      </FormItem>
      <FormItem label="Minimum Engagement Rate" name="minimumEngagementRate">
        <RadioGroup items={engagementRates} />
      </FormItem>
      <FormActions onSaveAsDraft={handleSaveAsDraft} />
    </Form>
  );
}

export function BudgetForm() {
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("id");

  const [form] = AntdForm.useForm();

  const { mutateAsync: updateBrandCampaignAsync } = useMutation({
    mutationFn: services.updateCampaign,
  });

  const updateQueryParams = useUpdateQueryParams();

  async function handleSubmit(data: types.BudgetFormData) {
    if (!campaignId) {
      // this should never happen, but adding here to make typescript happy
      return;
    }
    const transformedData = utilities.transformBudgetFormData(data);

    const res = await updateBrandCampaignAsync({
      id: campaignId,
      updates: transformedData,
    });
    if (res.success) {
      updateQueryParams({ step: "4" });
      return;
    }
  }

  function handleSaveAsDraft() {
    form.setFieldValue("action", "saveAsDraft");
    form.submit();
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Text fontWeight="SEMI_BOLD" fontSize="LARGE">
        Brand Target Audience
      </Text>
      <CampaignDatePickerWrapper>
        <FormItem label="Total Campaign Budget" name="totalCampaignBudget">
          <Input placeholder="Enter Budget" type="number" />
        </FormItem>
        <FormItem label="Cost Per Influencer" name="costPerInfluencer">
          <Input placeholder="Enter Cost" type="number" />
        </FormItem>
      </CampaignDatePickerWrapper>
      <Text fontWeight="SEMI_BOLD" fontSize="LARGE">
        Deliverables
      </Text>
      <DeliverablesWrapper>
        <FormItem label="No. of Images" name="noOfImages">
          <Input placeholder="eg. 10" type="number" />
        </FormItem>
        <FormItem label="No. of Reels" name="numberOfReels">
          <Input placeholder="eg.10" type="number" />
        </FormItem>
        <FormItem label="No. of Stories" name="numberOfStories">
          <Input placeholder="eg.10" type="number" />
        </FormItem>
      </DeliverablesWrapper>
      <FormActions onSaveAsDraft={handleSaveAsDraft} />
    </Form>
  );
}
