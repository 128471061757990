import * as types from "./RadioGroup.types";
import { RadioButton, RadioGroupWrapper } from "./RadioGroup.styles";

function RadioGroup({ items, onChange }: types.RadioGroupProps) {
  return (
    <RadioGroupWrapper size="large" onChange={onChange}>
      {items.map((item) => (
        <RadioButton value={item.value}>{item.label}</RadioButton>
      ))}
    </RadioGroupWrapper>
  );
}

export default RadioGroup;
