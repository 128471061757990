import { Steps } from "../../Components";
import {
  BrandProposalWrapper,
  PageTitle,
  StepsContent,
  StepsWrapper,
  StepTitle,
} from "./BrandProposal.styles";
import * as CONSTANTS from "./BrandProposal.constants";
import {
  CampaignDetails,
  ContentRequirement,
  InfluencerEligibility,
} from "./BrandProposal.HelperComponents";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import * as services from "./BrandProposal.services";
import * as utilities from "./BrandProposal.utilities";
import { useCurrentStep } from "./BrandProposal.hooks";

function BrandProposal() {
  const { campaignId } = useParams();

  const { data } = useQuery({
    queryKey: ["campaignProposals", campaignId],
    queryFn: () => services.getCampaignProposal(campaignId || ""),
    select: utilities.transformCampaignProposal,
  });

  const currentStep = useCurrentStep();

  if (!data) {
    return null;
  }

  function getStepForm() {
    if (!data) {
      return null;
    }
    switch (currentStep) {
      case 1:
        return <CampaignDetails campaign={data} />;

      case 2:
        return <InfluencerEligibility campaign={data} />;

      case 3:
        return <ContentRequirement campaign={data} />;

      default:
        return null;
    }
  }

  return (
    <BrandProposalWrapper>
      <PageTitle fontSize="X_LARGE" fontWeight="SEMI_BOLD" textAlign="CENTER">
        Campaign Proposal
      </PageTitle>
      <StepsWrapper>
        <StepsContent>
          <Steps
            items={CONSTANTS.BRAND_PROPOSAL_STEPS.map((step) => ({
              title: (
                <StepTitle fontSize="SMALL" fontWeight="SEMI_BOLD">
                  {step.title}
                </StepTitle>
              ),
            }))}
            current={currentStep - 1}
          />
        </StepsContent>
      </StepsWrapper>
      {getStepForm()}
    </BrandProposalWrapper>
  );
}

export default BrandProposal;
