import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as types from "./BrandSubmissions.types";
import * as services from "./BrandSubmissions.services";
import * as CONSTANTS from "./BrandSubmissions.constants";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Form as AntdForm } from "antd";
import {
  ApplicationSecondaryInfo,
  ApproveContentLeftSection,
  ApproveContentRightSection,
  ApproveLiveActionsWrapper,
  ApproveLiveContentContainer,
  ApproveLiveContentWrapper,
  BaseCommentWrapper,
  CaptionTitle,
  CheckboxContentWrapper,
  CommenterMetaInfo,
  FeedbackCommentsWrapper,
  FeedbackViewHeader,
  FirstFeedbackCommentItem,
  FirstFeedbackCommentSubmit,
  HastTagText,
  LabelInfoWrapper,
  NextActionWrapper,
  RejectReasonWrapper,
  ReplyButtonWrapper,
  ReplySubmitButtonWrapper,
  RequestChangeActionContent,
  RequestChangeActionsWrapper,
  RequestChangesMainWrapper,
  SubmissionActionsWrapper,
  SubmissionBasicInfo,
  SubmissionBasicSecond,
  SubmissionCommentWrapper,
  SubmissionContentInfoWrapper,
  SubmissionContentWrapper,
  SubmissionFeedbackViewWrapper,
  SubmissionFooterWrapper,
  SubmissionHeaderContent,
  SubmissionHeaderWrapper,
  SubmissionItemWrapper,
  SubmissionListWrapper,
  SubmissionMainWrapper,
  SubmissionProfileContent,
  SubmissionRejectReasonWrapper,
  SubmissionTitleWrapper,
  VersionOptionsImage,
  VersionSelectWrapper,
  VideoPlayer,
  WrongLinkHintText,
} from "./BrandSubmissions.styles";
import {
  Avatar,
  Button,
  ButtonLikeLink,
  Form,
  FormItem,
  Input,
  Modal,
  Select,
  Tag,
  Text,
  Checkbox,
  Skeleton,
} from "../../Components";
import { dateTime } from "../../Utilities";
import { useEffect, useMemo, useState } from "react";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import { ValueOf } from "../../Utilities/Types";
import * as utilities from "./BrandSubmissions.utilities";
import WomanOutlined from "../../Assets/Icons/WomanOutlined";
import ManOutlined from "../../Assets/Icons/ManOutlined";
import { useHlsPlayer } from "./BrandSubmissions.hooks";

function LabelInfo({ title, value }: types.LabelInfoProps) {
  return (
    <div>
      <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
        {value}
      </Text>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
        {title}
      </Text>
    </div>
  );
}

function SubmissionItem({ submission, path }: types.SubmissionItemProps) {
  const { partner, insights } = submission;
  return (
    <Link to={path}>
      <SubmissionItemWrapper>
        <SubmissionTitleWrapper>
          <Avatar src={partner.profile_picture} alt={partner.name} size={40} />
          <SubmissionBasicInfo>
            <Text fontWeight="SEMI_BOLD" fontSize="SMALL">
              {partner.name}
            </Text>
            <SubmissionBasicSecond>
              <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                Application ID: {submission.application_id}
              </Text>
            </SubmissionBasicSecond>
          </SubmissionBasicInfo>
        </SubmissionTitleWrapper>
        <ApplicationSecondaryInfo>
          <Text fontWeight="SEMI_BOLD" color="SECONDARY_TEXT" lineHeight="1">
            {partner.gender === "female" ? <WomanOutlined /> : <ManOutlined />}
          </Text>
          <Text
            fontSize="X_SMALL"
            fontWeight="SEMI_BOLD"
            color="SECONDARY_TEXT"
          >
            {partner.gender}
          </Text>
          {submission.tag ? <Tag color="GREEN">{submission.tag}</Tag> : null}
        </ApplicationSecondaryInfo>
        <LabelInfoWrapper>
          {insights.map((insight, index) => (
            <LabelInfo
              key={index}
              title={insight.label}
              value={insight.value}
            />
          ))}
        </LabelInfoWrapper>
        {submission.next_action ? (
          <NextActionWrapper>
            <Text fontWeight="SEMI_BOLD" fontSize="X_SMALL">
              {submission.next_action.label}
            </Text>
            <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
              {submission.next_action.event.key} on{" "}
              {dateTime.dateTime.formatDayMonthYear(
                submission.next_action.event.date
              )}
            </Text>
          </NextActionWrapper>
        ) : null}
      </SubmissionItemWrapper>
    </Link>
  );
}

export function SubmissionList({
  campaignId,
  statuses,
}: types.SubmissionListProps) {
  const { data } = useQuery({
    queryKey: [
      CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY,
      campaignId,
      ...statuses,
    ],
    queryFn: () => services.getReceivedSubmissions(campaignId, statuses),
    select: utilities.transformSubmittedApplications,
  });

  const location = useLocation();
  const updateQueryParams = useUpdateQueryParams();

  const params = new URLSearchParams(location.search);

  const applicationId = parseInt(params.get("application_id") || "");

  function getUpdatedUrl(applicationId: number) {
    const newParams = new URLSearchParams(params);
    newParams.set("application_id", applicationId.toString());
    newParams.set("version", "");
    return `${location.pathname}?${newParams.toString()}`;
  }

  const submissions = useMemo(() => data?.submissions || [], [data]);

  useEffect(() => {
    if (applicationId) {
      return;
    }
    if (!submissions.length) {
      return;
    }
    updateQueryParams({
      application_id: submissions[0].application_id.toString(),
    });
  }, [applicationId, submissions, updateQueryParams]);

  return (
    <SubmissionListWrapper>
      {data?.submissions.map((submission) => (
        <SubmissionItem
          key={submission.id}
          submission={submission}
          path={getUpdatedUrl(submission.application_id)}
        />
      ))}
    </SubmissionListWrapper>
  );
}

function SubmissionHeader({
  latestVersion,
  versions,
}: types.SubmissionHeaderProps) {
  const [searchParams] = useSearchParams();
  const updateQueryParams = useUpdateQueryParams();
  const selectedVersion = searchParams.get("version") || latestVersion;
  const versionOptions = versions.map((version) => ({
    label: `Version ${version.version}`,
    value: version.version,
    ...version,
  }));

  //TODO: Fix types here.
  function renderOptions(option: any) {
    const versionData = option.data as types.SubmissionVersionMeta;
    return (
      <div>
        <Text fontWeight="SEMI_BOLD" fontSize="X_SMALL">
          Version {versionData.version}
        </Text>
        <Text color="SECONDARY_TEXT" fontSize="XX_SMALL">
          Request for changes on{" "}
          {dateTime.dateTime.formatRelativeTime(versionData.created_at)}
        </Text>
        <VersionSelectWrapper>
          <VersionOptionsImage
            src={"https://picsum.photos/200/200" || versionData.thumbnail}
            alt={versionData.title}
          />
          <div>
            <Text fontWeight="SEMI_BOLD" fontSize="X_SMALL">
              {versionData.title}
            </Text>
            <Text color="SECONDARY_TEXT" fontSize="XX_SMALL">
              Uploaded:{" "}
              {dateTime.dateTime.formatRelativeTime(versionData.updated_at)}
            </Text>
          </div>
        </VersionSelectWrapper>
        <Text color="SECONDARY_TEXT" fontSize="XX_SMALL">
          {versionData.total_comments || "No"} Comments
        </Text>
      </div>
    );
  }
  return (
    <SubmissionHeaderWrapper>
      <SubmissionHeaderContent>
        <Text fontWeight="SEMI_BOLD">Submission</Text>
        <Select
          options={versionOptions}
          value={{ value: `Version ${selectedVersion}` }}
          onChange={(value) => {
            updateQueryParams({ version: (value as number).toString() });
          }}
          optionRenderer={renderOptions}
        />
      </SubmissionHeaderContent>
      <Button text="Influencer Details" type="text" />
    </SubmissionHeaderWrapper>
  );
}

function BaseComment({
  createdAt,
  name,
  profilePicture,
  text,
  videoTimestamp,
}: types.BaseCommentProps) {
  return (
    <BaseCommentWrapper>
      <Avatar src={profilePicture} alt={name} size={40} />
      <div>
        <CommenterMetaInfo>
          <Text fontWeight="SEMI_BOLD" fontSize="X_SMALL">
            {name}
          </Text>
          {videoTimestamp ? (
            <Tag color="GREEN">
              {dateTime.dateTime.formatSecondsToTime(videoTimestamp)}
            </Tag>
          ) : null}
          <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
            {dateTime.dateTime.formatRelativeTime(createdAt)}
          </Text>
        </CommenterMetaInfo>
        <Text fontSize="X_SMALL">{text}</Text>
      </div>
    </BaseCommentWrapper>
  );
}

function SubmissionComment({
  comment,
  feedbackId,
  submission,
}: types.SubmissionCommentProps) {
  const [shouldShowReply, setShouldShowReply] = useState(false);

  const queryClient = useQueryClient();

  const { mutateAsync: postCreateReply, isPending } = useMutation({
    mutationFn: services.postCreateReply,
    onSuccess: () => {
      setShouldShowReply(false);
      queryClient.invalidateQueries({
        queryKey: [
          CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY,
          CONSTANTS.CAMPAIGN_SUBMISSION_PROFILE_QUERY_KEY,
          submission.application_id,
        ],
      });
    },
  });

  function onReplySubmit(params: types.ReplyFormData) {
    postCreateReply({
      submission_id: submission.id,
      text: params.reply,
      feedback_id: feedbackId,
      parent_comment_id: comment.parent_comment.id,
    });
  }

  return (
    <SubmissionCommentWrapper>
      <BaseComment
        createdAt={comment.parent_comment.created_at}
        name={comment.parent_comment.name}
        profilePicture={comment.parent_comment.profile_picture}
        text={comment.parent_comment.text}
        videoTimestamp={comment.parent_comment.video_timestamp}
      />
      {comment.replies?.map((reply) => (
        <BaseComment
          key={reply.id}
          createdAt={reply.created_at}
          name={reply.name}
          profilePicture={reply.profile_picture}
          text={reply.text}
        />
      ))}
      {shouldShowReply ? (
        <Form<types.ReplyFormData> onSubmit={onReplySubmit}>
          <div>
            <FormItem
              name="reply"
              rules={[
                {
                  required: true,
                  message: "Please add reply",
                },
              ]}
            >
              <Input type="textarea" placeholder="Reply to Comment" />
            </FormItem>
            <ReplySubmitButtonWrapper>
              <Button text="Cancel" onClick={() => setShouldShowReply(false)} />
              <Button
                text="Submit"
                type="primary"
                htmlType="submit"
                loading={isPending}
              />
            </ReplySubmitButtonWrapper>
          </div>
        </Form>
      ) : (
        <ReplyButtonWrapper>
          <Button text="Reply" onClick={() => setShouldShowReply(true)} />
        </ReplyButtonWrapper>
      )}
    </SubmissionCommentWrapper>
  );
}

function SubmissionContentView({
  submission,
}: types.SubmissionContentViewProps) {
  return (
    <SubmissionContentWrapper>
      <SubmissionContentInfoWrapper>
        <Avatar
          src={submission.content.thumbnail}
          alt={submission.title}
          shape="square"
          size={40}
        />
        <div>
          <Text fontWeight="SEMI_BOLD">{submission.title}</Text>
          <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
            Uploaded{" "}
            {dateTime.dateTime.formatRelativeTime(submission.created_at)}
          </Text>
        </div>
      </SubmissionContentInfoWrapper>
      <CaptionTitle fontWeight="SEMI_BOLD">Captions</CaptionTitle>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
        {submission.caption}
      </Text>
      <HastTagText fontSize="X_SMALL" color="SECONDARY_TEXT">
        {submission.hashtags.join(", ")}
      </HastTagText>
    </SubmissionContentWrapper>
  );
}

function SubmissionFeedbackView({
  feedback,
  submission,
}: types.SubmissionFeedbackViewProps) {
  return (
    <SubmissionFeedbackViewWrapper>
      <FeedbackViewHeader>
        <Text fontWeight="SEMI_BOLD">Add feedback points</Text>
        <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
          Leave comments on the video
        </Text>
      </FeedbackViewHeader>
      {feedback ? (
        <FeedbackCommentsWrapper>
          {feedback.comments?.map((comment) => (
            <SubmissionComment
              key={comment.parent_comment.id}
              comment={comment}
              feedbackId={feedback.id}
              submission={submission}
            />
          ))}
        </FeedbackCommentsWrapper>
      ) : null}
    </SubmissionFeedbackViewWrapper>
  );
}

function ApproveLiveContent({ submission }: types.ApproveLiveContentProps) {
  const [showWrongReasonModal, setShowWrongReasonModal] = useState(false);
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateSubmissionAsWrongPost,
    isPending: isWrongPostPending,
  } = useMutation({
    mutationFn: services.updateSubmissionAsWrongPost,
    onSuccess: () => {
      setShowWrongReasonModal(false);
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
    },
  });

  const {
    mutateAsync: updateSubmissionStatus,
    isPending: isStatusUpdatePending,
  } = useMutation({
    mutationFn: services.updateSubmissionStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
    },
  });

  const { data } = useQuery({
    queryKey: ["applicationMeta"],
    queryFn: () => services.getApplicationMeta(),
    select: utilities.transformApplicationMeta,
  });

  function handleVerifyPost({ approveConsent }: { approveConsent: boolean }) {
    if (!approveConsent) {
      return;
    }
    updateSubmissionStatus({
      submissionId: submission.id,
      status: CONSTANTS.SUBMISSION_STATUS.POST_VERIFIED,
    });
  }

  function handleWrongPostSubmit(formData: {
    wrongReason: string[];
    comment?: string;
  }) {
    updateSubmissionAsWrongPost({
      submissionId: submission.id,
      reasons: formData.wrongReason,
      comment: formData.comment,
    });
  }

  const { videoRef } = useHlsPlayer();
  return (
    <ApproveLiveContentWrapper>
      <Text fontWeight="SEMI_BOLD">Verify the content on Instagram</Text>
      <ApproveLiveContentContainer>
        <ApproveContentLeftSection>
          <Text>Live on Instagram</Text>
          <VideoPlayer ref={videoRef} src={submission.content.url} controls />
          <ButtonLikeLink
            to={submission.post_url}
            text="Check post on instagram"
          />
        </ApproveContentLeftSection>
        <ApproveContentRightSection>
          <Text fontWeight="SEMI_BOLD" fontSize="MEDIUM">
            Verify the post to start getting live insights
          </Text>
          <Text color="SECONDARY_TEXT" fontSize="SMALL">
            Check the post on instagram and verify
          </Text>
          <Form onSubmit={handleVerifyPost}>
            <CheckboxContentWrapper>
              <FormItem
                name="approveConsent"
                rules={[
                  {
                    required: true,
                    message: "Please acknowledge the live content",
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox>
                  I acknowledge that the live post matches the submission we
                  approved.
                </Checkbox>
              </FormItem>
            </CheckboxContentWrapper>
            <ApproveLiveActionsWrapper>
              <Button
                text="Wrong link/post?"
                onClick={() => setShowWrongReasonModal(true)}
              />
              <Button
                text="Start getting insights"
                htmlType="submit"
                type="primary"
                loading={isStatusUpdatePending}
              />
            </ApproveLiveActionsWrapper>
          </Form>
        </ApproveContentRightSection>
      </ApproveLiveContentContainer>
      <Modal
        title="Wrong link/post?"
        open={showWrongReasonModal}
        onCancel={() => setShowWrongReasonModal(false)}
        footer={null}
      >
        <div>
          <WrongLinkHintText color="SECONDARY_TEXT" fontSize="SMALL">
            Are you sure you want to reject this submission? Once rejected the
            action can not be undone.
          </WrongLinkHintText>
          <Form onSubmit={handleWrongPostSubmit}>
            <FormItem
              name="wrongReason"
              label="Select reason(s)"
              rules={[
                {
                  required: true,
                  message: "Please add a reason",
                },
              ]}
            >
              <Select
                options={data?.wrongPostReasons || []}
                placeholder="Select reason(s)"
                mode="multiple"
              />
            </FormItem>
            <FormItem name="comment" label="Additional Comments">
              <Input type="textarea" placeholder="Add comment" />
            </FormItem>
            <SubmitActionButtons
              okayText="Request Changes"
              closeModal={() => setShowWrongReasonModal(false)}
              isPending={isWrongPostPending}
              okayHtmlType="submit"
            />
          </Form>
        </div>
      </Modal>
    </ApproveLiveContentWrapper>
  );
}

function SubmitActionButtons({
  closeModal,
  onOkay,
  okayText,
  isPending,
  okayHtmlType,
}: types.SubmitActionButtonsProps) {
  return (
    <RequestChangeActionContent>
      <Button text="Cancel" onClick={closeModal} />
      <Button
        text={okayText}
        type="primary"
        onClick={onOkay}
        loading={isPending}
        htmlType={okayHtmlType}
      />
    </RequestChangeActionContent>
  );
}

function RequestChanges({ submission, closeModal }: types.RequestChangesProps) {
  const { videoRef } = useHlsPlayer();

  const [form] = AntdForm.useForm();
  const [feedbackComment, setFeedbackComment] = useState<
    types.FirstFeedbackComment[]
  >([]);
  const queryClient = useQueryClient();

  const { mutateAsync: postCreateFeedback, isPending } = useMutation({
    mutationFn: services.postCreateFeedback,
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
    },
  });

  const [videoCommentTimestamp, setVideoCommentTimestamp] = useState(0);

  function handleAddFeedback({ comment }: { comment: string }) {
    setFeedbackComment((prev) => [
      ...prev,
      {
        videoTimestamp: videoCommentTimestamp,
        text: comment,
        id: new Date().getTime(),
        createdAt: new Date().toISOString(),
      },
    ]);
    setVideoCommentTimestamp(0);
    form.resetFields();
  }

  function handleFeedbackFocus() {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.pause();
    setVideoCommentTimestamp(Math.floor(videoRef.current.currentTime));
  }

  function onTimeUpdate(event: React.SyntheticEvent<HTMLVideoElement, Event>) {
    // if the video is playing, we don't want to update the timestamp
    if (!videoCommentTimestamp) {
      return;
    }
    setVideoCommentTimestamp(Math.floor(event.currentTarget.currentTime));
  }

  function handleVideoPause() {
    setTimeout(() => {
      if (!videoRef.current || !videoRef.current.paused) {
        return;
      }
      setVideoCommentTimestamp(Math.floor(videoRef.current.currentTime));
    }, 100);
  }

  function handleFeedbackSubmit() {
    postCreateFeedback({
      submissionId: submission.id,
      feedbacks: feedbackComment.map((comment) => ({
        text: comment.text,
        videoTimeStamp: comment.videoTimestamp,
      })),
    });
  }

  return (
    <RequestChangesMainWrapper>
      <VideoPlayer
        src={submission.content.url}
        controls
        ref={videoRef}
        onPlay={() => setVideoCommentTimestamp(0)}
        onTimeUpdate={onTimeUpdate}
        onPause={handleVideoPause}
      />
      <SubmissionFeedbackViewWrapper>
        <FeedbackViewHeader>
          <Text fontWeight="SEMI_BOLD">Add feedback points</Text>
          <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
            Leave comments on the video
          </Text>
        </FeedbackViewHeader>
        <FeedbackCommentsWrapper>
          {feedbackComment.map((comment) => (
            <FirstFeedbackCommentItem key={comment.id}>
              <BaseComment
                createdAt={comment.createdAt}
                name={"You"}
                profilePicture={""}
                text={comment.text}
                videoTimestamp={comment.videoTimestamp}
              />
            </FirstFeedbackCommentItem>
          ))}
          <Form onSubmit={handleAddFeedback} form={form}>
            <FormItem
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Please add comment",
                },
              ]}
            >
              <Input
                type="textarea"
                placeholder="Add you comment here"
                onFocus={handleFeedbackFocus}
              />
            </FormItem>
            {videoCommentTimestamp ? (
              <FirstFeedbackCommentSubmit>
                <Button
                  text={`Comment ${dateTime.dateTime.formatSecondsToTime(
                    videoCommentTimestamp
                  )}`}
                  htmlType="submit"
                />
              </FirstFeedbackCommentSubmit>
            ) : null}
          </Form>
        </FeedbackCommentsWrapper>
      </SubmissionFeedbackViewWrapper>
      <RequestChangeActionsWrapper>
        <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
          Add all the feedback at once to reduce the cycle of revisions
        </Text>
      </RequestChangeActionsWrapper>
      <SubmitActionButtons
        okayText="Submit Changes"
        onOkay={handleFeedbackSubmit}
        closeModal={closeModal}
        isPending={isPending}
      />
    </RequestChangesMainWrapper>
  );
}

function ApproveSubmissionModal({
  closeModal,
  submission,
}: types.ApproveSubmissionModalProps) {
  const queryClient = useQueryClient();

  const { mutateAsync: updateSubmissionStatus, isPending } = useMutation({
    mutationFn: services.updateSubmissionStatus,
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
    },
  });

  function handleApprove() {
    updateSubmissionStatus({
      submissionId: submission.id,
      status: CONSTANTS.SUBMISSION_STATUS.APPROVED,
    });
  }

  return (
    <div>
      <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
        Are you sure you want to select this submission? Once selected the
        action can not be undone.
      </Text>
      <SubmitActionButtons
        okayText="Approve Submission"
        onOkay={handleApprove}
        closeModal={closeModal}
        isPending={isPending}
      />
    </div>
  );
}

function RejectSubmissionModal({
  closeModal,
  submission,
}: types.RejectSubmissionModalProps) {
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ["applicationMeta"],
    queryFn: () => services.getApplicationMeta(),
    select: utilities.transformApplicationMeta,
  });

  const { mutateAsync: updateSubmissionStatus, isPending } = useMutation({
    mutationFn: services.updateSubmissionStatus,
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
    },
  });

  function handleRejectSubmit(formData: { rejectReason: string }) {
    const rejectionReason = data?.rejectionReasons.find(
      (reason) => reason.value === formData.rejectReason
    );
    if (!rejectionReason) {
      return;
    }
    updateSubmissionStatus({
      submissionId: submission.id,
      status: CONSTANTS.SUBMISSION_STATUS.REJECTED,
      rejectReason: {
        title: rejectionReason.value,
        description: rejectionReason.description,
      },
    });
  }

  return (
    <>
      <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
        Are you sure you want to reject this submission? Once rejected the
        action can not be undone.
      </Text>
      {data ? (
        <>
          <RejectReasonWrapper>
            <Text fontWeight="SEMI_BOLD" fontSize="SMALL">
              Reason for rejection
            </Text>
            <Form onSubmit={handleRejectSubmit}>
              <FormItem name="rejectReason">
                <Select
                  options={data?.rejectionReasons || []}
                  placeholder="Select a reason"
                  optionRenderer={({ label, value }) => (
                    <div>
                      <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
                        {value}
                      </Text>
                      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                        {label}
                      </Text>
                    </div>
                  )}
                />
              </FormItem>
              <SubmitActionButtons
                okayText="Reject Submission"
                closeModal={closeModal}
                isPending={isPending}
                okayHtmlType="submit"
              />
            </Form>
          </RejectReasonWrapper>
        </>
      ) : null}
    </>
  );
}

function SubmissionMain({ feedback, submission }: types.SubmissionMainProps) {
  const hasGivenFeedback = !!feedback?.comments?.length;
  const { videoRef } = useHlsPlayer();
  return (
    <SubmissionMainWrapper>
      <VideoPlayer src={submission.content.url} controls ref={videoRef} />
      {hasGivenFeedback ? (
        <SubmissionFeedbackView feedback={feedback} submission={submission} />
      ) : (
        <SubmissionContentView submission={submission} />
      )}
    </SubmissionMainWrapper>
  );
}
function SubmissionActions({ submission }: types.SubmissionActionsProps) {
  const [modalType, setModalType] = useState<ValueOf<
    typeof CONSTANTS.MODAL_TYPES
  > | null>(null);

  function closeModal() {
    setModalType(null);
  }

  function getModalContent() {
    if (modalType === CONSTANTS.MODAL_TYPES.REJECT) {
      return {
        title: "Confirm Reject Submission",
        content: (
          <RejectSubmissionModal
            closeModal={closeModal}
            submission={submission}
          />
        ),
      };
    }
    if (modalType === CONSTANTS.MODAL_TYPES.REQUEST_CHANGES) {
      return {
        title: "Request Changes",
        content: (
          <RequestChanges submission={submission} closeModal={closeModal} />
        ),
      };
    }
    return {
      title: "Confirm Submission",
      content: (
        <ApproveSubmissionModal
          closeModal={closeModal}
          submission={submission}
        />
      ),
    };
  }

  const { content, title } = getModalContent();
  return (
    <SubmissionActionsWrapper>
      <Button
        text="Reject"
        onClick={() => setModalType(CONSTANTS.MODAL_TYPES.REJECT)}
      />
      <Button
        text="Request Changes"
        onClick={() => setModalType(CONSTANTS.MODAL_TYPES.REQUEST_CHANGES)}
      />
      <Button
        text="Approve"
        type="primary"
        onClick={() => setModalType(CONSTANTS.MODAL_TYPES.APPROVE)}
      />
      <Modal
        open={!!modalType}
        onCancel={closeModal}
        title={title}
        width={
          modalType === CONSTANTS.MODAL_TYPES.REQUEST_CHANGES ? "80vw" : "500px"
        }
        footer={null}
      >
        {content}
      </Modal>
    </SubmissionActionsWrapper>
  );
}

function SubmissionProfile({ applicationId }: types.SubmissionProfileProps) {
  const [searchParams] = useSearchParams();
  const version = searchParams.get("version") || "";
  const { data, isPending } = useQuery({
    queryKey: [
      CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY,
      CONSTANTS.CAMPAIGN_SUBMISSION_PROFILE_QUERY_KEY,
      applicationId,
      version,
    ],
    queryFn: () =>
      services.getSubmissionDetails({
        applicationId,
        ...(version && { version }),
      }),
  });

  function getSubmissionContent() {
    if (!data) {
      return null;
    }
    const submissionStatus = data.result.submission.status;
    const hasGivenFeedback = !!data?.result?.feedback?.comments?.length;
    switch (submissionStatus) {
      case CONSTANTS.SUBMISSION_STATUS.VALIDATED:
        return (
          <>
            {data.result.versions ? (
              <SubmissionHeader
                versions={data.result.versions}
                latestVersion={data.result.latest_version}
              />
            ) : null}
            <SubmissionMain
              feedback={data.result.feedback}
              submission={data.result.submission}
            />
            {hasGivenFeedback ? (
              <SubmissionFooterWrapper>
                <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
                  We will let you know when the influencer submits a new version
                </Text>
              </SubmissionFooterWrapper>
            ) : (
              <SubmissionActions submission={data.result.submission} />
            )}
          </>
        );

      case CONSTANTS.SUBMISSION_STATUS.POST_UPLOADED:
        return <ApproveLiveContent submission={data.result.submission} />;

      case CONSTANTS.SUBMISSION_STATUS.REJECTED:
        return (
          <>
            <SubmissionRejectReasonWrapper>
              <Text fontWeight="SEMI_BOLD" fontSize="SMALL">
                Rejected Reason
              </Text>
              <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
                {data.result.submission.rejection_reasons
                  ?.map((reason) =>
                    reason.title === "Other" ? reason.description : reason.title
                  )
                  .join(", ")}
              </Text>
            </SubmissionRejectReasonWrapper>
            <SubmissionMain
              feedback={data.result.feedback}
              submission={data.result.submission}
            />
          </>
        );

      default:
        return (
          <SubmissionMain
            feedback={data.result.feedback}
            submission={data.result.submission}
          />
        );
    }
  }

  if (isPending) {
    return <Skeleton />;
  }
  if (!data?.result) {
    return null;
  }

  return (
    <SubmissionProfileContent>
      {getSubmissionContent()}
    </SubmissionProfileContent>
  );
}

export function SubmissionProfileContext() {
  const [searchParams] = useSearchParams();
  const applicationId = Number(searchParams.get("application_id") || 0);
  if (!applicationId) {
    return null;
  }
  return <SubmissionProfile applicationId={applicationId} />;
}
