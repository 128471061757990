import { useQuery } from "@tanstack/react-query";
import { useParams, useSearchParams } from "react-router-dom";
import * as services from "./BrandSubmissions.services";
import * as CONSTANTS from "./BrandSubmissions.constants";
import { ValueOf } from "../../Utilities/Types";
import {
  PageContent,
  PageTitle,
  SubmissionProfileWrapper,
  SubmissionTabs,
} from "./BrandSubmissions.styles";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import {
  SubmissionList,
  SubmissionProfileContext,
} from "./BrandSubmissions.HelperComponents";

function BrandSubmissions() {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const updateQueryParams = useUpdateQueryParams();
  const activeTab = (searchParams.get("tab") ||
    CONSTANTS.TABS.IN_PROGRESS) as ValueOf<typeof CONSTANTS.TABS>;

  const { data } = useQuery({
    queryKey: [
      CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY,
      campaignId,
      ...CONSTANTS.STATUS_MAP_TABS[activeTab],
    ],
    queryFn: () =>
      services.getReceivedSubmissions(
        campaignId || "",
        CONSTANTS.STATUS_MAP_TABS[activeTab]
      ),
  });

  return (
    <main>
      <PageTitle fontSize="X_LARGE" fontWeight="SEMI_BOLD">
        Submissions{" "}
        {data?.result?.campaign_title ? `“${data.result.campaign_title}”` : ""}
      </PageTitle>
      {campaignId ? (
        <SubmissionTabs
          onChange={(activeKey) =>
            updateQueryParams({
              tab: activeKey,
              application_id: "",
              version: "",
            })
          }
          items={[
            {
              key: CONSTANTS.TABS.IN_PROGRESS,
              label: "In-Progress",
              children: (
                <PageContent>
                  <SubmissionList
                    campaignId={campaignId}
                    statuses={
                      CONSTANTS.STATUS_MAP_TABS[CONSTANTS.TABS.IN_PROGRESS]
                    }
                  />
                  <SubmissionProfileWrapper>
                    <SubmissionProfileContext />
                  </SubmissionProfileWrapper>
                </PageContent>
              ),
            },
            {
              key: CONSTANTS.TABS.APPROVED,
              label: "Approved",
              children: (
                <PageContent>
                  <SubmissionList
                    campaignId={campaignId}
                    statuses={
                      CONSTANTS.STATUS_MAP_TABS[CONSTANTS.TABS.APPROVED]
                    }
                  />
                  <SubmissionProfileWrapper>
                    <SubmissionProfileContext />
                  </SubmissionProfileWrapper>
                </PageContent>
              ),
            },
            {
              key: CONSTANTS.TABS.REJECTED,
              label: "Rejected",
              children: (
                <PageContent>
                  <SubmissionList
                    campaignId={campaignId}
                    statuses={
                      CONSTANTS.STATUS_MAP_TABS[CONSTANTS.TABS.REJECTED]
                    }
                  />
                  <SubmissionProfileWrapper>
                    <SubmissionProfileContext />
                  </SubmissionProfileWrapper>
                </PageContent>
              ),
            },
          ]}
          defaultActiveKey={activeTab || CONSTANTS.SUBMISSION_STATUS.VALIDATED}
        />
      ) : null}
    </main>
  );
}

export default BrandSubmissions;
