import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as services from "./BrandProposal.services";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import * as CONSTANTS from "./BrandProposal.constants";
import { Message } from "../../Components";

export function useCurrentStep() {
  const [searchParams] = useSearchParams();
  const currentStep = searchParams.get("step") || "1";
  return Number(currentStep);
}

export function useUpdateCampaign(campaignId: number) {
  const queryClient = useQueryClient();
  const updateQueryParams = useUpdateQueryParams();
  const currentStep = useCurrentStep();
  const navigate = useNavigate();

  const updateStep = useCallback(() => {
    updateQueryParams({ step: (currentStep + 1).toString() });
  }, [currentStep, updateQueryParams]);

  const { mutateAsync: updateCampaign, isPending } = useMutation({
    mutationFn: services.updateCampaign,
    onSuccess: (data) => {
      console.log("🚀 ~ useUpdateCampaign ~ data:", data);
      if (!data?.result?.success) {
        Message.error(data.message);
        return;
      }
      if (currentStep === CONSTANTS.BRAND_PROPOSAL_STEPS.length) {
        navigate(`/brand/campaigns`);
        return;
      }
      queryClient.invalidateQueries({
        queryKey: ["campaignProposals", campaignId],
      });
      updateStep();
    },
  });
  return {
    updateCampaign,
    isPending,
    updateStep,
  };
}
