// TODO(Kartikey): Check the usage of `duration`
/* eslint-disable default-param-last */
import { message } from 'antd';

export const AppMessage = {
  success(content, duration = 4, onClose) {
    message.success(content, duration, onClose);
  },
  error(content, duration = 4, onClose) {
    message.error(content, duration, onClose);
  },
  info(content, duration = 4, onClose) {
    message.info(content, duration, onClose);
  },
  warning(content, duration = 4, onClose) {
    message.warning(content, duration, onClose);
  },
  warn(content, duration = 4, onClose) {
    message.warn(content, duration, onClose);
  },
  loading(content, duration = 4, onClose) {
    return message.loading(content, duration, onClose);
  },
  destroy: message.destroy,
};
export default AppMessage;
