import { Form as AntdForm } from "antd";
import * as types from "./Form.types";

export function Form<FormData>({
  children,
  onSubmit,
  form,
  initialValue,
}: types.FormProps<FormData>) {
  return (
    <AntdForm
      layout="vertical"
      onFinish={onSubmit}
      form={form}
      initialValues={initialValue}
    >
      {children}
    </AntdForm>
  );
}
