import { TagWrapper } from "./Tag.styles";
import * as types from "./Tag.types";

function Tag({ children, color, shape = "round" }: types.TagProps) {
  return (
    <TagWrapper
      $color={color}
      fontSize="XX_SMALL"
      fontWeight="SEMI_BOLD"
      $shape={shape}
    >
      {children}
    </TagWrapper>
  );
}

export default Tag;
