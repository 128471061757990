import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, ButtonLikeLink, Form, Text } from "../../Components";
import {
  CampaignDetailsForm,
  TargetAudienceForm,
} from "./CreateCampaign.HelperComponents";
import {
  CreateCampaignWrapper,
  FormActionsWrapper,
  TempBanner,
} from "./CreateCampaign.styles";
import * as services from "./CreateCampaign.services";
import * as utilities from "./CreateCampaign.utilities";
import * as types from "./CreateCampaign.types";
import { useNavigate } from "react-router-dom";

export default function CreateCampaign() {
  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: ["campaignMeta"],
    queryFn: services.getCampaignMeta,
    select: utilities.transformCampaignMeta,
  });

  const {
    mutateAsync: createBrandCampaignAsync,
    isPending: isCreateBrandCampaignLoading,
  } = useMutation({
    mutationFn: services.postCreateCampaign,
  });

  async function handleSubmitCreateCampaign(
    formData: types.CreateCampaignFormData
  ) {
    if (!data) {
      return;
    }
    const transformedData = utilities.transformCreateCampaignFormData(
      formData,
      data.rawResponse
    );
    const res = await createBrandCampaignAsync(transformedData);
    if (!res.success) {
      // Handle error
      return;
    }
    navigate("/");
  }
  return (
    <CreateCampaignWrapper>
      <Text fontSize="LARGE" fontWeight="SEMI_BOLD" lineHeight="1.2">
        Campaign form
      </Text>
      <Text color="SECONDARY_TEXT" fontSize="SMALL">
        Request a call back to create a campaign.
      </Text>
      <TempBanner>Banner comes here</TempBanner>
      <Text fontSize="LARGE" fontWeight="SEMI_BOLD" lineHeight="1.2">
        Hi, help us with the following information
      </Text>
      <Text color="SECONDARY_TEXT" fontSize="SMALL">
        Our expert will assist you in launching your campaign.
      </Text>
      <Form onSubmit={handleSubmitCreateCampaign}>
        <CampaignDetailsForm
          goals={data?.goals || []}
          types={data?.types || []}
        />
        <TargetAudienceForm
          ages={data?.ages || []}
          genders={data?.genders || []}
          locations={data?.locations || []}
        />
        <FormActionsWrapper>
          <ButtonLikeLink text="Cancel" to="/" type="LINK" />
          <Button
            text="Confirm call back"
            type="primary"
            size="large"
            htmlType="submit"
            loading={isCreateBrandCampaignLoading}
          />
        </FormActionsWrapper>
      </Form>
    </CreateCampaignWrapper>
  );
}
