import { useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Form,
  FormItem,
  Input,
  Select,
  Text,
} from "../../Components";
import { useUpdateCampaign } from "./BrandProposal.hooks";
import {
  ActionFooterWrapper,
  CampaignDetailsWrapper,
  FormWrapper,
  ReferenceItemWrapper,
  RulesWrapper,
  SectionWrapper,
} from "./BrandProposal.styles";
import * as types from "./BrandProposal.types";
import DeleteOutlined from "../../Assets/Icons/DeleteOutlined";
import { useQuery } from "@tanstack/react-query";
import * as services from "./BrandProposal.services";
import { validateHashtags } from "./BrandProposal.utilities";

function ActionFooter({ okayText, isPending }: types.ActionFooterProps) {
  return (
    <ActionFooterWrapper>
      <Button text="Cancel" type="text" size="large" />
      <Button
        text={`${okayText} >`}
        type="primary"
        htmlType="submit"
        size="large"
        loading={isPending}
      />
    </ActionFooterWrapper>
  );
}

export function CampaignDetails({ campaign }: types.CampaignDetailsProps) {
  const { isPending, updateCampaign } = useUpdateCampaign(campaign.id);

  function handleOnBasicDetailSubmit(formData: types.BasicDetailFormData) {
    updateCampaign({
      id: campaign.id,
      title: formData.title,
      description: formData.description,
    });
  }

  return (
    <CampaignDetailsWrapper>
      <FormWrapper>
        <Form
          onSubmit={handleOnBasicDetailSubmit}
          initialValue={{
            title: campaign.title,
            description: campaign.description,
            goals: campaign.goals,
            type: campaign.type,
            gender: campaign.gender,
            location: campaign.location,
            ageGroup: campaign.ageGroup,
            language: campaign.language,
          }}
        >
          <Text fontWeight="SEMI_BOLD">Campaign details:</Text>
          <FormItem
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Please add title",
              },
            ]}
          >
            <Input placeholder="Enter Campaign Title" />
          </FormItem>
          <FormItem
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please add description",
              },
            ]}
          >
            <Input placeholder="Enter Campaign Description" type="textarea" />
          </FormItem>
          <FormItem label="Goals" name="goals">
            <Select options={[]} disabled />
          </FormItem>
          <FormItem label="Type" name="type">
            <Select options={[]} disabled />
          </FormItem>
          <Text fontWeight="SEMI_BOLD">Target Audience details:</Text>
          <FormItem label="Gender" name="gender">
            <Select options={[]} disabled />
          </FormItem>
          <FormItem label="Location" name="location">
            <Select options={[]} disabled />
          </FormItem>
          <FormItem label="Age Group" name="ageGroup">
            <Select options={[]} disabled />
          </FormItem>
          <FormItem label="Languages" name="language">
            <Select options={[]} disabled />
          </FormItem>
          <ActionFooter
            okayText="Influencer eligibility"
            isPending={isPending}
          />
        </Form>
      </FormWrapper>
    </CampaignDetailsWrapper>
  );
}

export function InfluencerEligibility({
  campaign,
}: types.CampaignDetailsProps) {
  const { updateStep } = useUpdateCampaign(campaign.id);
  function handleInfluencerEligibilityUpdate() {
    updateStep();
  }

  const initialFormData = campaign.eligibility.reduce((acc, eligibility) => {
    acc[eligibility.key] = eligibility.value;
    return acc;
  }, {} as Record<string, string>);

  return (
    <CampaignDetailsWrapper>
      <FormWrapper>
        <Form
          onSubmit={handleInfluencerEligibilityUpdate}
          initialValue={initialFormData}
        >
          <Text fontWeight="SEMI_BOLD">
            What type of Influencers are you looking for?
          </Text>
          {campaign.eligibility.map((eligibility) => (
            <FormItem label={eligibility.label} name={eligibility.key}>
              <Select options={[]} disabled />
            </FormItem>
          ))}
          <ActionFooter okayText="Content requirements" isPending={false} />
        </Form>
      </FormWrapper>
    </CampaignDetailsWrapper>
  );
}

export function ContentRequirement({ campaign }: types.CampaignDetailsProps) {
  const [referenceItems, setReferenceItems] = useState<{ id: number }[]>([]);
  const { isPending, updateCampaign } = useUpdateCampaign(campaign.id);

  const { data } = useQuery({
    queryKey: ["campaignProposalsMeta"],
    queryFn: () => services.campaignProposalMeta(),
  });

  function handleAddReference() {
    setReferenceItems((items) => [...items, { id: new Date().getTime() }]);
  }

  function handleUpdateContentRequirements(
    formData: Record<"rules", number[]> & Record<string, string>
  ) {
    const { contentVision, captions, hashtags, rules, ...references } =
      formData;

    const formattedData = {
      hashtags: hashtags,
      rules: rules.map((ruleId) =>
        data?.result.rules.find((r) => r.id === ruleId)
      ),
      reference_materials: Object.values(references).map((reference) => ({
        url: reference,
      })),
      content_idea: contentVision,
      caption: captions,
    };
    updateCampaign({
      id: campaign.id,
      otherUpdates: formattedData,
    });
    console.log("🚀 ~ ContentRequirement ~ formattedData:", formattedData);
  }

  function handleDeleteReference(id: number) {
    setReferenceItems((items) => items.filter((item) => item.id !== id));
  }

  return (
    <CampaignDetailsWrapper>
      <FormWrapper>
        <Form onSubmit={handleUpdateContentRequirements}>
          <SectionWrapper>
            <Text fontWeight="SEMI_BOLD">
              Describe your content requirement:
            </Text>
          </SectionWrapper>
          <FormItem
            label="Describe your vision for content"
            name="contentVision"
            rules={[
              {
                required: true,
                message: "Please add your vision for content",
              },
            ]}
          >
            <Input placeholder="Enter Campaign Title" type="textarea" />
          </FormItem>
          <SectionWrapper>
            <Text fontWeight="SEMI_BOLD">Provide reference (if any):</Text>
            <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
              Mention the links of the references
            </Text>
          </SectionWrapper>
          {referenceItems.map(({ id }, index) => (
            <FormItem
              name={`${id}`}
              key={id}
              label={
                <ReferenceItemWrapper onClick={() => handleDeleteReference(id)}>
                  <Text>Reference {index + 1}</Text>
                  <Text color="ERROR_COLOR">
                    <DeleteOutlined />
                  </Text>
                </ReferenceItemWrapper>
              }
            >
              <Input placeholder="Add Reference" />
            </FormItem>
          ))}
          <Button text="+ Add Item" onClick={handleAddReference}></Button>
          <SectionWrapper>
            <Text fontWeight="SEMI_BOLD">
              You provide captions and hashtags (if any):
            </Text>
            <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
              Have specific way of captions and hashtags?
            </Text>
          </SectionWrapper>
          <FormItem label="Captions" name="captions">
            <Input placeholder="Enter Caption" type="textarea" />
          </FormItem>
          <FormItem
            label="Hashtags"
            name="hashtags"
            rules={[
              {
                validator: (_, value) => {
                  return validateHashtags(value);
                },
              },
            ]}
          >
            <Input placeholder="Enter Hashtags" type="textarea" />
          </FormItem>
          <SectionWrapper>
            <Text fontWeight="SEMI_BOLD">Submission rules</Text>
            <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
              Check the rules you want to apply
            </Text>
          </SectionWrapper>
          <FormItem name="rules">
            <CheckboxGroup name="rules">
              {data?.result.rules.map((rule) => (
                <RulesWrapper key={rule.id}>
                  <Checkbox value={rule.id}>{rule.rule}</Checkbox>
                </RulesWrapper>
              ))}
            </CheckboxGroup>
          </FormItem>
          <ActionFooter okayText="Content requirements" isPending={isPending} />
        </Form>
      </FormWrapper>
    </CampaignDetailsWrapper>
  );
}
