export const API_ENDPOINTS = {
  APPLICATIONS: "/client/brand/applications",
  GET_APPLICATION: "/client/brand/application/:applicationId",
  APPLICATION: "/application",
} as const;

export const APPLICATION_STATUS = {
  APPLIED: "Applied",
  SAVED: "Saved",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
} as const;

export const MATCH_COLORS = ["#EC4BA7", "#F9C53E", "#3C88FC"];

export const NON_MATCH_COLOR = "#E0E3E5";

export const ACTION_MODAL_TYPES = {
  REJECT: "reject",
  APPROVE: "approve",
} as const;

export const REJECT_REASONS = [
  {
    value: "Followers count is less than expected",
    label: "The influencer does not has enough followers",
  },
  {
    value: "Engagement is less than expected",
    label: "The influencer's engagement score is low",
  },
  {
    value: "Target Audience Mismatch",
    label: "The target audience is not as per brand requirement.",
  },
  {
    value: "Poor content quality",
    label: "Lacks creativity and originality",
  },
  {
    value: "Limited reach of post",
    label: "Posts are not reaching a wide audience",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const INSIGHT_TYPES = {
  FOLLOWER_COUNT: "follower_count",
  ENGAGEMENT_RATE: "engagement_rate",
  POST_COUNT: "post_count",
} as const;
