export const API_ENDPOINTS = {
  USER_EXISTS: "/business-user/exists",
  SIGN_IN: "/business-user/signin",
  SIGN_UP: "/business-user/signup",
  GOOGLE_SIGN_IN: "/google/oauth",
};

export const SCREEN_TYPES = {
  USER_EXISTS: "USER_EXISTS",
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
};

export const GOOGLE_OAUTH_CLIENT_ID =
  "684693913305-tuba4c5f0iv7j3mi7l025tgqur7rade2.apps.googleusercontent.com";
