import HomeOutlined from "../../Assets/Icons/HomeOutlined";
import LogoutOutlined from "../../Assets/Icons/LogoutOutlined";
import NotificationOutlined from "../../Assets/Icons/NotificationOutlined";
import SettingOutlined from "../../Assets/Icons/SettingOutlined";

export const MENU_ITEMS = [
  {
    label: "Dashboard",
    key: "home",
    Icon: HomeOutlined,
    path: "/",
  },
  {
    label: "Notifications",
    key: "notifications",
    Icon: NotificationOutlined,
  },
  {
    label: "Settings",
    key: "settings",
    Icon: SettingOutlined,
  },
  {
    label: "Logout",
    key: "logout",
    Icon: LogoutOutlined,
  },
];
