import { http } from "../../Utilities";
import { url } from "../../Utilities/Url";
import * as CONSTANTS from "./BrandInsights.constants";
import * as types from "./BrandInsights.types";

export function getCampaignInsights({
  campaignId,
  submissionId,
}:
  | { campaignId: string; submissionId: null }
  | { campaignId: null; submissionId: string }) {
  if (campaignId) {
    return http.get<types.CampaignInsightRawResponse>(
      url.replacePathParams(CONSTANTS.API_ENDPOINTS.CAMPAIGN_INSIGHTS, {
        campaignId,
      })
    );
  }
  return http.get<types.CampaignInsightRawResponse>(
    url.replacePathParams(CONSTANTS.API_ENDPOINTS.SUBMISSION_INSIGHTS, {
      submissionId,
    })
  );
}
